// import 'bootstrap-tour/build/js/bootstrap-tour-standalone.js'
// import 'bootstrap-tour/build/css/bootstrap-tour-standalone.css'
import { take, select, put, call, fork, cancel, cancelled, delay } from 'redux-saga/effects';
import { getAuth } from 'firebase/auth';
import { getDatabase, get, ref, set } from 'firebase/database';
import {
  USER_DATA_CHANGED,
  ADD_TASK_REQUEST,
  ADD_LIST_REQUEST,
  SHOW_LISTS_SETTINGS,
  CHANGE_TASKS_TAB,
  ADD_EVENT_REQUEST,
  CHANGE_ACTIVE_VIEW,
} from '../actions';
// import MyTour from './tour.jsx'
import Analytics from '../analytics.jsx';
import { getNewestChangelogDate } from '../components/Changelog';
import { TrevorMessage } from '../components/TrevorMessageBubble';
import { race } from 'redux-saga/effects';
import * as scriptjs from 'scriptjs';

const { $, confirm } = window;

const db = getDatabase();

const loadUserProfile = () => {
  let user = getAuth().currentUser;
  if (!user) return null;

  return get(ref(db, `/users/${user.uid}`)).then((snapshot) => snapshot.val());
};

const loadUserReadonlyDetails = () => {
  let user = getAuth().currentUser;
  if (!user) return null;

  return get(ref(db, `/users-readonly/${user.uid}`)).then((snapshot) => snapshot.val());
};

const saveStepToUserProfile = (step) => {
  let user = getAuth().currentUser;
  if (!user) return null;

  return set(ref(db, `/users/${user.uid}/walkthroughStep`), step);
};

const setInitialChangelogStepForNewUsers = (userData) => {
  const user = getAuth().currentUser;
  const { changelogStep, walkthroughStep } = userData;
  if (changelogStep) return;
  if (walkthroughStep === undefined) {
    const date = getNewestChangelogDate();
    return set(ref(db, `/users/${user.uid}/changelogStep`), date);
  }
};

let __cbTourClose;
const monitorTourClose = () => {
  return new Promise((resolve) => {
    __cbTourClose = () => {
      resolve(true);
    };
  });
};
const skipTour = (tour, currentStep) => {
  if (confirm('Are you sure you want to skip the tutorial? It only takes a minute.')) {
    tour && tour.end();
    __cbTourClose && __cbTourClose();
    if (currentStep) {
      Analytics.event({ category: 'walkthrough', action: 'Skipped Walkthrough at Step ' + currentStep });
    } else {
      Analytics.event({ category: 'walkthrough', action: 'Skipped Walkthrough at Step 1' });
    }
  } else {
    tour && tour.restart();
  }
  // TODO
  // data-role='end'
};

const tourBox = async (steps, currentStep) => {
  if (!window.Tour) {
    await new Promise((resolve) => {
      scriptjs(
        'https://cdnjs.cloudflare.com/ajax/libs/bootstrap-tour/0.11.0/js/bootstrap-tour-standalone.min.js',
        () => {
          resolve();
        }
      );
    });
  }

  let tour = new window.Tour({
    name: 'basicTour',
    orphan: true,
    storage: false,
    template:
      "<div class='popover tour'><div class='arrow'></div><h3 class='popover-title'></h3><div class='popover-content'></div><a href='#' class='skip'>x</a><img src='/images/trevor_shape.png' class='popover-logo animated rotate infinite' /></div>",
    onShown: () => $('.skip').click(() => skipTour(tour, currentStep)),
  });
  tour.addSteps(steps);
  tour.init();
  tour.restart();

  return tour;
};

let tour;

function* walkthrough() {
  while (true) {
    let action = yield take(USER_DATA_CHANGED); // a bit hack - we must be logged after that
    if (
      action.user &&
      action.user.terms &&
      action.user.personalContext?.done
      // &&
      // (!action.user.premiumContext || action.user.premiumContext?.done)
    ) {
      // Must include all signup steps
      break;
    } else {
      yield delay(1000);
    }
  }

  do {
    const userReadonlyDetails = yield call(loadUserReadonlyDetails);
    if (!userReadonlyDetails?.premiumContext || userReadonlyDetails?.premiumContext?.done) {
      break;
    }
    yield delay(1000);
  } while (true);

  let userData, fullname, firstName;
  for (let count = 0; count < 3 && !fullname; ++count) {
    userData = yield call(loadUserProfile);
    fullname = userData.displayName;
    if (fullname) {
      firstName = fullname.split(/\s/)[0];
    } else {
      yield delay(500);
    }
  }

  setInitialChangelogStepForNewUsers(userData);

  let uiReset;

  // yield put(addEventWithTaskSuccess({eventId, tempId, title}, taskId)) - calls an action
  // const stateTasks = yield select(state => state.tasks) - returns state of the app

  try {
    let step = userData.walkthroughStep || 1;
    // step = 2 // USE FOR TESTING

    while (true) {
      if (!userData) {
        yield delay(10 * 1000);
        continue;
      }

      switch (step) {
        case 1: {
          Analytics.event({ category: 'walkthrough', action: `Initiated Step ${step} - Add a Task` });
          // UI SETUP
          $('.walkthrough-overlay, .walkthrough-header, .walkthrough-text, .walkthrough-text-mobile').css(
            'display',
            'block'
          );
          $('.walkthrough-overlay h1').append('Welcome' + (firstName ? `, ${firstName}` : '!'));
          $('.walkthrough-overlay .walkthrough-text').append('Add a task you have to do today.<br/>(ex: Groceries)');
          $('.walkthrough-overlay .walkthrough-text-mobile').append(
            "Add a task you have to do today.<br/>(ex: Groceries)<br/><br/>Tap ' + ' below"
          );
          if (window.innerWidth > 768) {
            $('.walkthrough-overlay').append(
              '<a href="#" class="walkthrough-skip-button" style="position: absolute; top: 15px; right: 30px; position: absolute; font-size: 40px; font-weight: 100;">&times;</a>'
            );
          }
          $('.bottom-nav-add-new-task').addClass('highlight infinite');
          $('#add-new-task-panel').addClass('walkthrough');
          if ($('#add-new-task-panel').length) {
            $('#add-new-task-panel input').get(0).focus();
          }
          $('#right-pane').css('display', 'none'); // space to display left-pane in center
          $('#calendar-container').css('opacity', '0'); // hides calendar on mobile
          $('#calendar-setup-assistant').css('opacity', 0); // hides calendar assistant
          $('#left-pane').css('margin', '0 auto'); // centers left-pane
          TrevorMessage.show(' ');
          // UI RESET
          uiReset = () => {
            // Hide special task input
            $('.walkthrough-overlay, .walkthrough-header, .walkthrough-text').fadeOut('slow');
            $('.walkthrough-overlay h1').fadeOut('slow').empty();
            $('.walkthrough-overlay p').fadeOut('slow').empty();
            $('.walkthrough-overlay a').fadeOut('slow').empty();
            $('.bottom-nav-add-new-task').removeClass('pulsing-bg infinite');
            // Task input field to bottom
            $('#add-new-task-panel').animate(
              {
                bottom: '0px',
              },
              1000
            );
            // Left pane to left
            setTimeout(() => {
              $('#left-pane').animate(
                {
                  margin: '0 auto 0 0',
                },
                1000
              );
            }, 1000);
            // Show right pane
            setTimeout(() => {
              $('#add-new-task-panel').removeClass('walkthrough');
              $('#right-pane').css('display', 'block'); // show right-pane
              $('#left-pane').css('margin', '0 auto 0 0');
            }, 2000);
          };

          let mobileStep;
          $('.bottom-nav-add-new-task').click((e) => {
            mobileStep = -102; // Sends the user to the Mobile walkthrough starting at -102
          });

          function* waitForSkipButtonClick() {
            while (true) {
              const result = yield call(
                () =>
                  new Promise((resolve) => {
                    $('.walkthrough-skip-button').click((e) => {
                      resolve(true);
                    });
                  })
              );

              if (result) {
                break;
              }
            }
          }

          function* waitForAddTaskRequest() {
            yield take(ADD_TASK_REQUEST);
          }

          yield race({
            task: call(waitForAddTaskRequest),
            skip: call(waitForSkipButtonClick),
          });

          uiReset();

          yield delay(2050); // wait for uiReset animations before changing step as it re-renders home
          step = mobileStep ? -102 : step + 1;
          saveStepToUserProfile(step);
          break;
        }

        // DESKTOP STEP 2
        case 2: {
          Analytics.event({ category: 'walkthrough', action: `Initiated Step ${step} - Calendar Setup` });
          $('#calendar-container').css('opacity', '0'); // hides calendar
          TrevorMessage.show(' ');
          $('#calendar-setup-assistant').animate(
            {
              opacity: 1,
            },
            500
          );
          // $('#calendar-setup-assistant').css('visibility', 'hidden');
          // yield delay(500);

          // yield take('WALKTHROUGH_NEXT_STEP');
          while (!window.__WALKTHROUGH_NEXT_STEP) {
            yield delay(500);
          }

          $('#calendar-setup-assistant').animate(
            {
              opacity: 0,
            },
            500
          );
          $('#calendar-container').animate(
            {
              opacity: 1,
            },
            500
          );
          yield delay(500);
          saveStepToUserProfile(++step);
          break;
        }

        // DESKTOP STEP 3
        case 3: {
          // Initiate Step 3
          Analytics.event({ category: 'walkthrough', action: `Initiated Step ${step} - Scheduling` });
          TrevorMessage.show(' '); // Temporary Fix

          // Load tasks in inbox and animate drag & drop
          yield delay(1000);
          let task = $('.task-card').first();
          task.find('.task-card-title span').append(' 👆 ➜ 🗓');
          // $('.task-card .task-card-title span').first().append(' 👆 ➜ 🗓');
          $('.fc-day-today').addClass('animated pulsing-bg infinite');
          // let clone = task.clone().appendTo('.inbox-list > div:nth-child(2)');
          // clone.css({ position: 'absolute', opacity: '0.75', top: '124px' }).addClass('animated slideOutRight');
          task.addClass('animated pulsing-bg infinite');
          TrevorMessage.show('Drag and drop your task onto your schedule');

          yield take(ADD_EVENT_REQUEST);
          // RESET
          task.removeClass('animated pulsing-bg infinite');
          $('.fc-day-today').removeClass('animated pulsing-bg infinite');
          // clone.hide();
          TrevorMessage.show(' '); // Temporary Fix
          yield delay(3000); // Temporary Fix
          TrevorMessage.show('Great job! Your task is now synced with your scheduling calendar.');
          // yield delay(4000); // Temporary Fix

          // Next
          yield delay(6000);
          Analytics.event({ category: 'walkthrough', action: `Completed Walkthrough - ${step}` });
          saveStepToUserProfile(++step);
          TrevorMessage.reset();
          break;
        }

        // MOBILE STEP 2
        case -102: {
          Analytics.event({ category: 'walkthrough', action: `Initiated Mobile Step 2 - Calendar Setup` });
          $('#calendar-setup-assistant').css('opacity', 0);
          $('#calendar-setup-assistant').animate(
            {
              opacity: 1,
            },
            500
          );

          // yield take('WALKTHROUGH_NEXT_STEP');
          while (!window.__WALKTHROUGH_NEXT_STEP) {
            yield delay(500);
          }

          $('#calendar-setup-assistant').animate(
            {
              opacity: 0,
            },
            500
          );
          $('#calendar-container').animate(
            {
              opacity: 1,
            },
            500
          );
          yield delay(500);
          saveStepToUserProfile(--step);
          break;
        }

        // MOBILE STEP 3
        case -103: {
          $('#calendar').css('visibility', 'visible');
          Analytics.event({ category: 'walkthrough', action: `Initiated Mobile Step 3 - Scheduling` });
          yield delay(200);
          $('.bottom-nav-tasks').addClass('pulsing-bg');

          tour = yield tourBox(
            [
              {
                element: '.bottom-nav-tasks',
                title: 'Go to your Tasks',
                // content: "I store your tasks in the Task Panel, organized into #Lists.",
                placement: 'top',
              },
            ],
            step
          );

          yield take(CHANGE_ACTIVE_VIEW);
          $('.bottom-nav-tasks').removeClass('pulsing-bg');
          yield delay(200);
          $('.task-card').first().addClass('pulsing-bg');

          tour = yield tourBox(
            [
              {
                element: '.inbox-list:first-child .task-card:first-child > div:first-child',
                title: 'Long press to schedule it',
                content:
                  'To <b>Schedule a Task</b> in your <b>calendar</b>, simply drag & drop it over your schedule at the desired time.',
                placement: 'bottom',
              },
            ],
            step
          );

          yield take(ADD_EVENT_REQUEST);
          $('.task-card').first().removeClass('pulsing-bg');
          yield delay(200);

          tour = yield tourBox(
            [
              {
                element: '',
                title: 'Task synced with calendar!',
                content:
                  "We successfully scheduled your task in your (Google) calendar, across your devices.<div class='popover-navigation'><button class='btn btn-default' data-role='next'>Next »</button></div>",
                placement: 'bottom',
              },
            ],
            step
          );
          yield delay(3000);
          tour.end();

          Analytics.event({
            category: 'walkthrough',
            action: `Completed Mobile Scheduling Walkthrough - Step 3 Mobile`,
          });
          saveStepToUserProfile(--step);
          break;
        }

        default:
          yield delay(10 * 1000);
      }
    }
  } finally {
    if (yield cancelled()) {
      yield call(saveStepToUserProfile, 5);
      uiReset && uiReset();
      tour && tour.end();
    }
  }
}
export default function* main() {
  let walkthroughTask = yield fork(walkthrough);
  if (yield monitorTourClose()) {
    yield cancel(walkthroughTask);
  }
}

// Legacy Add Task List Walkthrough
// case 3: {
//     Analytics.event({ category: 'walkthrough', action: `Initiated Step ${step}` })
//     $('.task-panel-tabs-settings').addClass('pulsing-bg infinite')
//     // TrevorMessage.show("GAGA")
//     // UI RESET
//     uiReset = () => $('.task-panel-tabs-settings').removeClass('pulsing-bg infinite')

//     yield take(SHOW_LISTS_SETTINGS)
//     uiReset()
//     yield delay(200)
//     TrevorMessage.show("Create or import #Lists with tasks. For example: 'Home', 'Work', 'Personal' or 'Urgent'.")

//     $('.add-new-list button').addClass('animated pulse infinite')
//     yield take(ADD_LIST_REQUEST)

//     while (true) {
//         let action = yield take(CHANGE_TASKS_TAB)
//         if (action.tab === 'inbox') break;
//     }
//     TrevorMessage.show(" ")

//     uiReset()
//     saveStepToUserProfile(++step);
//     break;
// }

// Legacy Add Task to specific list
// case 4: {
//     Analytics.event({ category: 'walkthrough', action: `Initiated Step ${step}` })
//     TrevorMessage.show("Add task to your new #list by typing or selecting '#' in the bottom field.")

//     yield delay(100)
//     // tour = tourBox([
//     //     {
//     //         element: "#add-new-task-panel",
//     //         title: "Add task to your new #List",
//     //         content: "Type or tap '#' and select your new list to add a task to it.",
//     //         placement: "right"
//     //     }
//     // ], step);
//     yield take(ADD_TASK_REQUEST)
//     // tour.end()

//     TrevorMessage.show(`Great job${firstName ? (", " + firstName + "!") : '!'}`)

//     Analytics.event({ category: 'walkthrough', action: `Completed Task Lists Walkthrough - Step ${step}`})
//     saveStepToUserProfile(++step);
//     break;
// }

// // Legacy Add Task List Walkthrough
// case -103: {
//     Analytics.event({ category: 'walkthrough', action: `Initiated Step 3 Mobile` })
//     yield take(CHANGE_ACTIVE_VIEW, 0)
//     yield delay(100)
//     $('.task-panel-tabs-settings').addClass('pulsing-bg infinite')
//     // UI RESET
//     uiReset = () => $('.task-panel-tabs-settings').removeClass('pulsing-bg infinite')

//     yield take(SHOW_LISTS_SETTINGS)
//     uiReset()
//     yield delay(200)

//     tour = tourBox([{
//         element: ".add-new-list",
//         title: "Create a List of Tasks",
//         content: "Create a new List to proceed. For example: 'Home', 'Work', 'Personal' or 'Urgent'.",
//         placement: "top"
//     }], step);
//     yield take(ADD_LIST_REQUEST)

//     tour = tourBox([
//         {
//             element: ".task-panel-tabs-inbox-mobile",
//             title: "Go back to your Tasks",
//             content: "Great job! Click on the back arrow to go back to your tasks.",
//             placement: "bottom"
//         }
//     ], step);

//     yield delay(100)

//     while (true) {
//         let action = yield take(CHANGE_TASKS_TAB)
//         if (action.tab === 'inbox') break;
//     }

//     saveStepToUserProfile(--step);
//     break;
// }

// Legacy Add Task to specific list
// case -104: {
//     Analytics.event({ category: 'walkthrough', action: `Initiated Step 4 Mobile` })

//     yield delay(100)
//     tour = tourBox([
//         {
//             element: ".bottom-nav-add-new-task",
//             title: "Add task to new #List",
//             content: "Type or tap '#' and select your new list to add a task to it.",
//             placement: "top"
//         }
//     ], step);
//     yield take(ADD_TASK_REQUEST)

//     tour = tourBox([
//         {
//             element: ".bottom-nav-schedule",
//             title: `You've got the hang of it`,
//             content: "Let's get back to your schedule.",
//             placement: "top"
//         }
//     ], step);
//     yield take(CHANGE_ACTIVE_VIEW, 1)

//     tour = tourBox([
//         {
//             element: ".monk",
//             title: `We make a good team${firstName ? (", " + firstName + "!") : '!'}`,
//             content: "Now that you can organize your Tasks, feel free to furhter personalize in 'Settings', where you can also chat with my makers. <div class='popover-navigation'><button class='btn btn-default' data-role='next'>Complete Tour!</button></div>",
//             placement: "bottom"
//         }
//     ], step);

//     Analytics.event({ category: 'walkthrough', action: `Completed Task Lists Walkthrough - Step 4 Mobile`})
//     step = 5 // completed
//     saveStepToUserProfile(step);
//     break;
// }
