import { take, takeEvery, put, fork, call, cancel, select } from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import { fbOps } from '../common/firebase';
import { getDatabase, ref, onValue, orderByChild, startAt, query, off, update } from 'firebase/database';
import { VOTE_INTEGRATION, voteIntegrationFailed, userDataChanged, USER_CHANGED } from '../actions';
import { logError, ObjectValuesWithKey, activeModules } from 'common/utils';
import {
  ActivityRecord,
  defaultPrefs,
  formatDate,
  isPremium,
  PremiumTraits,
  SubscriptionInfo,
  User,
  UserDetails,
  UserInvitationData,
  UserPrefs,
  UserReadonlyDetails,
} from 'shared';
import moment from 'moment';
import { getLocalPrefs } from 'operations/settings';

function* voteIntegration(action) {
  const { integration } = action;
  console.log('Vote', action);
  let user = yield select((state) => state.account.user);
  try {
    yield call(fbOps.voteIntegration, user.uid, integration);
  } catch (err) {
    logError(err, { hint: 'Error saving user vote' });
    alert('Sorry, there was an error saving your vote. Please try again later');
    yield put(voteIntegrationFailed(action, err));
  }
}

// const normalizeEmail = email => encodeURIComponent(email).replace(/\./g,'%2e')

export const MAX_FREE_EVENTS = 3;

function getFreeEventsCount(activity: ActivityRecord[]) {
  // console.log('analyze activity', activity)
  const today = moment().startOf('day').toISOString();
  const items = Object.values(activity).filter((it) => it.ts >= today);
  const scheduledTaskEvents = {};
  for (let it of items) {
    if (it.type === 'event_create') scheduledTaskEvents[it.data.eventId] = it;
    else if (it.type === 'event_delete') delete scheduledTaskEvents[it.data.eventId];
  }
  const consumedCount = Math.min(MAX_FREE_EVENTS, Object.keys(scheduledTaskEvents).length);
  let count = MAX_FREE_EVENTS - consumedCount;
  // console.log('activity', {activity, today, items, scheduledTaskEvents, count})
  return count;
}

// ignore braintree subscriptions, only paddle
const stripOldSubscriptionsInline = (user) => {
  const { subscriptions = {} } = user;
  for (let key in subscriptions) {
    const { braintreeSubscriptionId } = subscriptions[key];
    if (braintreeSubscriptionId) {
      delete subscriptions[key];
    }
  }
};

const extractPremiumDetails = (userReadonlyDetails: UserReadonlyDetails) => {
  const { subscriptions = {}, premiumContext, premiumPlan } = userReadonlyDetails;
  let { premiumUntil } = userReadonlyDetails;
  const subs = Object.values(subscriptions);

  const userIsPremium = isPremium(userReadonlyDetails);

  const isEarlyBird = !premiumUntil && !premiumPlan;

  if (isEarlyBird) {
    // old users
    premiumUntil = moment().add(3, 'months').startOf('month').format('YYYY-MM-DD');
    // stored.userReadonly.premiumPlan = 'early'
  }

  const dateNow = formatDate(new Date());

  let activeSubscriptionId = null;
  let activeSubscription: SubscriptionInfo = null;

  // take the last subscription, it's the most recent one with biggest id
  const lastSubscription = subs
    .filter(({ status }) => status !== 'Cancelled')
    .reduce((latestSub, sub) => {
      if (!latestSub) return sub;
      if (sub.subscriptionId > latestSub.subscriptionId) return sub;
      return latestSub;
    }, null);

  if (lastSubscription) {
    const { subscriptionId } = lastSubscription;
    activeSubscriptionId = subscriptionId;
    activeSubscription = lastSubscription;
  }

  const premiumTraits: PremiumTraits = {
    isTrial: false,
    isTrialNoCard: false,
    isTrialConsumed: false,
    isPastDue: activeSubscription?.status === 'PastDue',
    hasActiveSubscription: !!activeSubscription,
    isSubscriptionCancelled: !activeSubscription && subs.length > 0,
    canUpgrade: false,
    canCancel: !!activeSubscriptionId,
    hadPremium: subs.length > 0,
    isEarlyBird,
    isPatreon: premiumPlan === 'patreon',
  };

  if (activeSubscription?.status === 'Trial') {
    premiumTraits.isTrial = true;
    premiumTraits.isTrialNoCard = false;
  } else if (!premiumTraits.hadPremium && premiumContext?.trialEnd && dateNow <= premiumContext.trialEnd) {
    premiumTraits.isTrial = true;
    premiumTraits.isTrialNoCard = true;
  }
  premiumTraits.isTrialConsumed =
    !premiumTraits.isTrial &&
    ((premiumContext?.trialEnd && dateNow > premiumContext.trialEnd) || premiumTraits.hadPremium);

  if (
    !activeSubscription &&
    premiumUntil < dateNow &&
    !premiumTraits.isPatreon &&
    !premiumTraits.isEarlyBird // need this?
  ) {
    premiumTraits.canUpgrade = true;
  }

  return {
    activeSubscription,
    activeSubscriptionId,
    // isFreeTrial: status === 'Trial',
    userIsPremium,
    premiumUntil,
    premiumTraits,
  };
};

function* userChannel(fbUser) {
  let {
    uid,
    email,
    emailVerified,
    // providerData
  } = fbUser;
  console.log('start userChannel', fbUser);
  const db = getDatabase();
  let userRef = ref(db, 'users/' + uid);
  let userReadonlyRef = ref(db, 'users-readonly/' + uid);
  let invitationsRef = ref(db, 'invitations/' + uid);
  let activityRef = query(
    ref(db, 'users-activity/' + uid),
    orderByChild('ts'),
    startAt(moment().subtract(1, 'day').toISOString())
  );

  let data: Partial<UserDetails> = { uid, email, emailVerified };
  if (fbUser.photoURL) data.photoURL = fbUser.photoURL;
  if (fbUser.displayName) data.displayName = fbUser.displayName;

  data.timezoneOffset = new Date().getTimezoneOffset() * -1;
  try {
    const { timeZone, locale } = Intl.DateTimeFormat().resolvedOptions();
    if (timeZone && locale) data = { ...data, timeZone, locale };
  } catch (err) {
    console.log('Error getting timezone and locale', err);
  }

  yield call(update, userRef, data);

  const channel = eventChannel((emit) => {
    // console.log('subscribe to refs');
    onValue(userRef, (snap) => emit({ userInfo: snap.val() }));
    onValue(userReadonlyRef, (snap) => emit({ userReadonly: snap.val() || {} }));
    onValue(invitationsRef, (snap) => emit({ invitations: snap.val() || {} }));
    onValue(activityRef, (snap) => emit({ activity: snap.val() || {} }));

    return () => {
      off(userRef);
      off(userReadonlyRef);
      off(invitationsRef);
      off(activityRef);
    };
  });

  let stored: {
    userInfo?: UserDetails;
    userReadonly?: UserReadonlyDetails;
    invitations?: UserInvitationData;
    activity?: ActivityRecord[];
  } = {};

  while (true) {
    let { userInfo, userReadonly, invitations, activity } = yield take(channel);
    // console.log('read from user channel', { userInfo, userReadonly, invitations, activity });

    if (userInfo) stored.userInfo = userInfo;
    if (userReadonly) stored.userReadonly = userReadonly;
    if (invitations) stored.invitations = invitations;
    if (activity) stored.activity = activity;

    if (stored.userInfo && stored.userReadonly && stored.invitations && stored.activity) {
      let {
        displayName,
        customDisplayName,
        photoURL,
        createdAt,
        defaultCalendarId,
        disabledCalendars,
        votes,
        changelogStep,
        recurringRecoveryStep,
        subscriptionPendingProcessing,
      } = stored.userInfo;
      let {
        integrations = {},
        // googleAccessToken,
        todoistToken,
        todoistSyncToken,
        todoistUserId,
        todoistLang,
        todoistHideUnassigned,
        todoistHideAssignedToOthers,
        todoistHideWithoutDueDate,
        todoistSyncDateToTodoist,
        outlookReminderMinutesBeforeStart,
        walkthroughStep,
      } = stored.userInfo;

      let { sent, received } = stored.invitations;

      // const googleAccountInfo = providerData.find((p) => p.providerId === 'google.com');
      const integrationsAccounts = Object.values(integrations);
      // if (
      //   googleAccountInfo &&
      //   !integrationsAccounts.some((it) => it.type === 'google' && it.email === googleAccountInfo.email)
      // ) {
      //   integrationsAccounts.unshift({
      //     type: 'google',
      //     email: googleAccountInfo.email,
      //     name: googleAccountInfo.displayName,
      //     expiry_date: +stored.userInfo.googleAccessTokenExpiryDate,
      //     access_token: stored.userInfo.googleAccessToken,
      //     refresh_token: stored.userInfo.googleRefreshToken,
      //     errorMessage: stored.userInfo.googleErrorMessage,
      //     mainAccount: true,
      //   });
      // }

      let data: Partial<User> = {
        uid,
        email,
        emailVerified,
        photoURL,
        displayName: customDisplayName || displayName,
        createdAt,
        defaultCalendarId,
        disabledCalendars,
        votes,
        changelogStep,
        recurringRecoveryStep,
        walkthroughStep,
        subscriptionPendingProcessing,
        integrations: integrationsAccounts,
        accounts: {
          // google: (googleAccountInfo && googleAccountInfo.email) || !!googleAccessToken,
          outlook: {
            // loading from DB '' means no reminder, 0 is at the time of event, non-zero is minutes before
            // in redux null means no reminder, 0 is at the time of event, non-zero is minutes before
            // default is 15 min
            defaultReminderMinutesBeforeStart:
              outlookReminderMinutesBeforeStart === ''
                ? null
                : outlookReminderMinutesBeforeStart === undefined
                ? 15
                : +outlookReminderMinutesBeforeStart,
          },
          todoist: todoistToken
            ? {
                userId: todoistUserId,
                lang: todoistLang || 'en',
                hideUnassigned: !!todoistHideUnassigned,
                hideAssignedToOthers: !!todoistHideAssignedToOthers,
                hideWithoutDueDate: !!todoistHideWithoutDueDate,
                syncDateToTodoist: !!todoistSyncDateToTodoist,
                accessToken: todoistToken,
                syncToken: todoistSyncToken,
              }
            : null,
        },
        invitations: {
          sent: sent ? ObjectValuesWithKey(sent) : [],
          received: received ? ObjectValuesWithKey(received) : [],
        },
      };

      stripOldSubscriptionsInline(stored.userReadonly);
      const {
        activeSubscription,
        activeSubscriptionId,
        //  isFreeTrial,
        premiumTraits,
        userIsPremium,
        premiumUntil,
      } = extractPremiumDetails(stored.userReadonly);
      // const userIsPremium = isPremium(stored.userReadonly);
      const modules = activeModules(stored.userReadonly, userIsPremium);
      const freeEventsLeft = getFreeEventsCount(stored.activity);
      // it's possible to be premium until dateX having cancelled subscription

      const prefs: Partial<UserPrefs> = stored.userReadonly.prefs || {};
      let { user_schedule, start_of_day = 9 } = prefs;
      if (!user_schedule?.days || !user_schedule?.zones) {
        // 9:00 - 20:00 - a bit wider range
        const start = start_of_day * 60;
        const end = start + 10 * 60;
        user_schedule = {
          days: [0, 1, 1, 1, 1, 1, 0], // 0 - false, 1 - true, in future could also be object with custom zones for the day
          zones: [{ start, end }],
        };
      }

      const localPrefs = getLocalPrefs();

      // !!! the order here is important
      data = {
        ...data,
        ...stored.userReadonly,
        prefs: {
          ...defaultPrefs,
          ...prefs,
          ...localPrefs,
          user_schedule,
        },
        isPremium: userIsPremium,
        premiumUntil,
        // isFreeTrial,
        premiumTraits,
        activeSubscriptionId,
        activeSubscription,
        modules,
        freeEventsLeft,
      };
      yield put(userDataChanged(data));
    }
  }
}

function* settingsSaga() {
  yield takeEvery(VOTE_INTEGRATION, voteIntegration);

  let userThread, fbUser;

  while (true) {
    let action = yield take(USER_CHANGED);
    if (!action.fbUser || (fbUser && fbUser.uid !== action.fbUser.uid)) {
      if (userThread) yield cancel(userThread);
      userThread = null;
    }

    fbUser = action.fbUser;

    if (fbUser && !userThread) {
      userThread = yield fork(userChannel, fbUser);
    }
  }
}

export default settingsSaga;
