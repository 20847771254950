export interface TodoistTask {
  id: string;
  content: string;
  labels?: string[];
  duration?: {
    amount: number;
    unit: 'minute' | 'day';
  };
  due?: {
    date: string;
    is_recurring: boolean;
    lang: string;
    string: string;
  };

  // TODO more
}

export interface Task {
  id: string;
  title: string;
  completed?: boolean;
  removed?: boolean;
  listId: string;
  created?: number;
  lastModified?: number;
  eventId?: string;
  eventBeginDate?: string;
  eventEndDate?: string;
  nextInstEventId?: string;
  duration?: number;
  dueDate?: string;
  recurrence?: string;
  isRecurring?: boolean; // readOnly
  assignedTo?: string;
  todoist?: TodoistTask;
  vendor?: string;
  item_order?: number;
  parent_id?: string;
  recurringEventIds?: { [key: string]: string };
  queue?: boolean;
}

export interface SharedList {
  sharedId: string;
  members: {
    email: string;
    displayName: string;
    owner?: boolean;
    pending?: boolean;
  }[];
}

export interface List {
  id: string;
  name: string;
  enabled?: boolean;
  default?: boolean;
  order?: number;
  origin?: string;
  integrationId?: string;
  color?: string;
  removed?: boolean;
  todoist?: any;
  vendor?: string;
  queue?: boolean;
  associatedCalendarId?: string;
  associatedUserScheduleZoneId?: string;
  shared?: SharedList;
}

export interface Filter {
  id: string;
  name: string;
  item_order?: number;
  color?: string;
  query?: string;
  removed?: boolean;
  todoist?: any;
}

export interface Invitation {
  id: string;
  inviterDisplayName: string;
  inviterEmail: string;
  inviteeEmail: string;
  listId: string;
  listName: string;
  listColor: string;
  status: string;
}
export interface UserInvitationData {
  sent?: Record<string, Invitation>;
  received?: Record<string, Invitation>;
}

export interface Calendar {
  id: string;
  integrationId?: string;
  title: string;
  color?: string;
  isDefault?: boolean;
  order?: number;
  removed: boolean;
  defaultReminders?: any[];
  canEdit?: boolean;
  pushNotificationsNotSupported?: boolean;
  syncToken?: string;
  watchExpTimestamp?: number;
  watchResourceId?: string;
}

export interface Reminder {
  method: string;
  minutes: number;
}

export interface RemindersStruct {
  useDefault?: boolean;
  overrides: Reminder[];
}

export interface CalendarEvent {
  id?: string;
  extraLongId?: string;
  suggestId?: string;
  // suggestReminderMinutesBeforeStart?: number;
  title: string;
  calendarId: string;
  htmlLink?: string;
  taskId?: string;
  extendedPropertyTaskId?: string;
  completedTaskId?: string;
  completedRecurringTaskId?: string;
  taskCompleted?: boolean;
  removed?: boolean;
  reminders?: RemindersStruct;
  beginDate?: string;
  endDate?: string;
  allDay?: boolean;
  recurringEventId?: string;
  recurrence?: string[] | string;
  color?: string;
  description?: string;
  isFree?: boolean;
}

export interface IntegrationConfig {
  id: string;
  type: string;
  name: string;
  access_token: string;
  refresh_token?: string;
  expiry_date: number;
  readonly?: boolean;
  errorMessage?: string;
  errorReason?: string;
}

export interface IntegrationConfigGoogle extends IntegrationConfig {
  email: string;
  locale: string;
  refresh_token: string;
}

export interface IntegrationConfigMicrosoft extends IntegrationConfig {
  homeAccountId: string;
  email: string;
  version?: number;
}

export interface NotificationConfig {
  prefs: { days: string } | AutoScheduleConfig;
  time: string;
}
export type NotificationType = 'morning' | 'startMyDay' | 'autoSchedule';

export interface AutoScheduleConfig {
  days?: number;
  strategy?: string;
}

export interface SubscriptionInfo {
  paddle?: any;
  status?: 'Cancelled' | 'Active' | 'PastDue' | 'Trial';
  subscriptionId?: string;
  braintreeSubscriptionId?: string;
  timestamp?: string;
}

export interface UserDetails {
  uid: string;
  defaultCalendarId?: string;
  bot_notifications?: Record<NotificationType, NotificationConfig>;
  initialized?: boolean;
  idsVer?: number;
  createdAt?: string;
  integrations?: Record<string, IntegrationConfig>; // IntegrationConfigMicrosoft | IntegrationConfigGoogle>;
  disabledCalendars?: string[];
  todoistToken?: string;
  todoistUserId?: string;
  todoistLang?: string;
  todoistSyncToken?: string;
  todoistIntegrationLevel?: number;
  todoistHideUnassigned?: boolean;
  todoistHideAssignedToOthers?: boolean;
  todoistHideWithoutDueDate?: boolean;
  todoistSyncDateToTodoist?: boolean;
  outlookReminderMinutesBeforeStart?: number | string;
  email?: string;
  emailVerified?: boolean;
  displayName?: string;
  customDisplayName?: string;
  photoURL?: string;
  lastActivityMessenger?: string;
  fb_messenger_psid?: string;
  timeZone?: string;
  timezoneOffset?: number;
  locale?: string;
  chatPermissions?: ChatPermissions;
  votes?: Record<string, boolean>;
  changelogStep?: string;
  recurringRecoveryStep?: number;
  subscriptionPendingProcessing?: boolean;
  walkthroughStep?: number;
  // TODO
}

export interface ObsoleteUserDetails extends UserDetails {
  googleAccessToken?: string;
  googleRefreshToken?: string;
  googleAccessTokenExpiryDate?: number;
  googleErrorMessage?: string;
  googleErrorReason?: string;
}

export interface UserPrefs {
  default_task_duration: number;
  add_task_nlp: boolean;
  due_date_is_exact_date: boolean;
  recurring_due_date_is_exact_date: boolean;
  due_date_in_scheduling_queue: boolean;
  max_all_day_events: number;
  overdue_suggestions_limit: number;
  inbox_suggestions_limit: number;
  duration_predictions: boolean;
  start_of_day?: number;
  user_schedule: UserScheduleConfig;
  auto_schedule_config?: AutoScheduleConfig;
}

export interface UserPersonalContext {
  done?: boolean;
  role?: string[];
  fieldOfWork?: string[];
  referral?: string;
  openText?: string;
}

export interface UserPremiumContext {
  done?: boolean;
  trialStart?: string;
  trialEnd?: string;
}

export interface UserReadonlyDetails {
  modules?: Record<string, any>;
  own_refcode?: string;
  boosters?: {
    type: 'multiple_accounts';
    date: string;
    validUntil: string;
  }[];
  personalContext?: UserPersonalContext;
  premiumContext?: UserPremiumContext;
  prefs?: UserPrefs;
  premiumPlan?: string;
  premiumPrice?: string;
  premiumUntil?: string;
  extraChatUntil?: string;
  subscriptions?: Record<string, SubscriptionInfo>;
  terms?: {
    datetime?: number;
    news?: boolean;
    platform?: boolean;
    startMyDay?: boolean;
  };
}

export interface ActivityRecord {
  type: string;
  ts: string;
  data: any;
}

export interface UserScheduleConfigItem {
  id?: string;
  displayName?: string;
  days: (0 | 1)[];
  zones: { start: number; end: number }[];
}
export interface UserScheduleConfig extends UserScheduleConfigItem {
  moreItems?: UserScheduleConfigItem[];
}

export interface PremiumTraits {
  isTrial: boolean;
  isTrialNoCard: boolean;
  isTrialConsumed: boolean;
  isPastDue: boolean;
  hasActiveSubscription: boolean;
  isSubscriptionCancelled: boolean;
  canCancel: boolean;
  canUpgrade: boolean;
  hadPremium: boolean;
  isEarlyBird: boolean;
  isPatreon: boolean;
}

export interface User {
  uid: string;
  email: string;
  displayName: string;
  photoURL: string;
  emailVerified: boolean;
  defaultCalendarId?: string;
  changelogStep?: string;
  isPremium: boolean;
  premiumPlan?: string;
  premiumUntil?: string;
  premiumTraits: PremiumTraits;
  activeSubscription?: SubscriptionInfo; // TODO
  activeSubscriptionId?: string;
  premiumPrice?: string | number;
  // isFreeTrial?: boolean;
  subscriptionPendingProcessing?: boolean;
  prefs?: {
    default_task_duration?: number;
    duration_predictions?: boolean;
    start_of_day?: number;
    user_schedule?: UserScheduleConfig;
  };
  personalContext?: UserPersonalContext;
  premiumContext?: UserPremiumContext;
  createdAt?: string;
  modules?: Record<string, boolean>;
  freeEventsLeft?: number;
  disabledCalendars?: string[];
  votes?: Record<string, boolean>;
  recurringRecoveryStep?: number;
  walkthroughStep?: number;
  integrations?: IntegrationConfig[];
  accounts?: Record<string, any>;
  invitations?: {
    sent: Invitation[];
    received: Invitation[];
  };
  // TODO rest
}

export enum UserType {
  Anon = 'Anon',
  Free = 'Free',
  // Trial,
  Prem = 'Prem',
  // Prem2
}

export interface PredictionItemString {
  value: string;
  prob: number;
  hint?: 'common';
}
export interface PredictionItemNumber {
  value: number;
  prob: number;
  hint?: 'common';
}

export interface TaskPredictionSet {
  days: PredictionItemString[];
  duration: PredictionItemNumber[];
  start_times: PredictionItemNumber[];
}

// Chat

export interface ChatPermissions {
  [key: string]: boolean;
}

export type ChatActionGetInfoSimpleTopic = 'lists' | 'calendars';
export interface ChatActionGetInfoTasksTopic {
  tasks: {
    listId: string;
  };
}
export interface ChatActionGetInfoEventsTopic {
  events: {
    fromDate: string;
    toDate: string;
    search?: string;
    maxResults?: number;
  };
}
export type ChatActionGetInfo =
  | ChatActionGetInfoSimpleTopic
  | ChatActionGetInfoTasksTopic
  | ChatActionGetInfoEventsTopic;

export type ChatActionDocsTopic = 'general' | 'support' | 'actions';

export interface ChatActionTask {
  id?: string;
  title: string;
  listId?: string;
  duration?: number;
  dueDate?: string;
}

export interface ChatActionExistingTask {
  id: string;
  title?: string;
  listId?: string;
  duration?: number;
  dueDate?: string;
}

export interface ChatActionTaskEvent {
  id: string;
  taskId?: string;
  calendarId?: string;
  title?: string;
  beginDate: string;
  duration?: number;
  endDate?: string;
  allDay?: boolean;
  reminders?: RemindersStruct;
}

export interface ChatActionNewTaskEvent extends Omit<ChatActionTaskEvent, 'id' | 'title'> {
  id?: string;
  title: string;
  recurrence?: string;
}

export interface ChatActionUpdateEvent extends ChatActionTaskEvent {
  recurrence?: string;
}

export interface ChatActionScheduleTask {
  id: string;
  beginDate: string;
  endDate?: string;
  duration?: number;
  allDay?: boolean;
  recurrence?: string;
  reminders?: RemindersStruct;
}

export interface ChatMessageRichData {
  text?: string;
  get?: ChatActionGetInfo[];
  docs?: ChatActionDocsTopic;
  removeTasks?: string[];
  removeEvents?: string[];

  addTasks?: ChatActionTask[];
  editTasks?: ChatActionExistingTask[];
  addTaskEvents?: ChatActionNewTaskEvent[];
  updateEvents?: ChatActionUpdateEvent[];
  scheduleTasks?: ChatActionScheduleTask[];
  // addList?: List[];
  // removeList?: string[];
}

export interface ChatMessageData {
  content: string; //| ChatMessageRichData;
  role?: string;
  isResolve?: boolean;
  isInjected?: boolean;
  isHidden?: boolean;
}

export interface ChatMessage extends ChatMessageData {
  id?: string;
  ts?: { seconds: number; nanoseconds: number };
}

export type TaskInput = Omit<Partial<Task>, 'id'>;
export type IntegrationData = any;

export interface IntegrationContext {
  getData: <T extends IntegrationData>() => Promise<T>;
  setData: (data: IntegrationData) => Promise<void>;
  updateData: (data: IntegrationData) => Promise<void>;
  deleteData: () => Promise<void>;

  getUserTimezoneOffset(): number;

  getWebhookUrlBase(): string;
  getUniqueToken(): string;
  getDisabledListsIds(): Promise<Set<string>>;
}

export interface CalendarEventBasic {
  id: string;
  calendarId: string;
  title: string;
  beginDate: string;
  endDate: string;
  allDay: boolean;
}

export interface ErrorItem {
  item: string;
  error?: string;
}

export interface TaskIntegration {
  connect(
    ctx: IntegrationContext,
    readOnly: boolean,
    redirectUri: string,
    transparentData: string
  ): Promise<{
    overrideRedirectUri?: string;
  }>;
  postRedirect(
    body: any,
    params: object,
    query: object
  ): Promise<{
    transparentData: string;
    verificationCode: string;
    integrationId: string;
    displayId: string; // could be email or user id, identifying the user in the integration
  }>;
  finishConnect(ctx: IntegrationContext, verificationCode: string): Promise<void>;

  disconnect(ctx: IntegrationContext): Promise<void>;

  getListsAndTasksIncremental(ctx: IntegrationContext): Promise<{ lists: List[]; tasks: Task[]; errors?: ErrorItem[] }>;

  addTask(ctx: IntegrationContext, task: TaskInput): Promise<Task>;
  saveTask(ctx: IntegrationContext, task: Task): Promise<Task>;
  removeTask(ctx: IntegrationContext, task: Task): Promise<void>;

  moveTask(ctx: IntegrationContext, taskId: string, fromListId: string, toListId: string): Promise<void>;
  reorderTasks(ctx: IntegrationContext, tasksAfter: Task[], tasksBefore: Task[]): Promise<Task[]>;

  onScheduleChange(ctx: IntegrationContext, task: Task, associatedEvent: CalendarEventBasic | null): Promise<void>;

  handleWebhook(
    req: { method: string; headers: any; body: any; path: string; query: any },
    requestCtx: (uniqueToken: string) => Promise<IntegrationContext>,
    sendResponse: (status: number, body: any) => void // optional, call it only if handling is slow and you want to send a response before it's done
  ): Promise<{
    lists: List[];
    tasks: Task[];
  } | null>;

  handleAppFocus?(ctx: IntegrationContext): Promise<{
    lists: List[];
    tasks: Task[];
    errors?: ErrorItem[];
  } | null>;
}

export interface CalendarIntegration {
  connect(
    ctx: IntegrationContext,
    readOnly: boolean,
    redirectUri: string,
    transparentData: string
  ): Promise<{
    overrideRedirectUri?: string;
  }>;
  postRedirect(
    body: any,
    params: object,
    query: object
  ): Promise<{
    transparentData: string;
    verificationCode: string;
    integrationId: string;
    displayId: string; // could be email or user id, identifying the user in the integration
  }>;
  finishConnect(ctx: IntegrationContext, verificationCode: string): Promise<void>;

  disconnect(ctx: IntegrationContext): Promise<void>;

  getCalendars(ctx: IntegrationContext): Promise<Calendar[]>;

  getEventsForRange(
    ctx: IntegrationContext,
    calendarId: string,
    from: string,
    to: string,
    props: { showDeleted?: boolean; searchStr?: string; maxResults?: number }
  ): Promise<CalendarEvent[]>;
  getEventById(ctx: IntegrationContext, calendarId: string, eventId: string): Promise<CalendarEvent>;

  getRecurringInstances(
    ctx: IntegrationContext,
    calendarId: string,
    eventId: string,
    timeMin: string,
    maxResults: number
  ): Promise<CalendarEvent[]>;

  createEvent(ctx: IntegrationContext, event: CalendarEvent): Promise<CalendarEvent>;
  saveEvent(ctx: IntegrationContext, event: CalendarEvent): Promise<CalendarEvent>;
  deleteEvent(ctx: IntegrationContext, event: CalendarEvent): Promise<void>;

  watchCalendar(ctx: IntegrationContext, calendarId: string): Promise<void>;
  revokeToken(ctx: IntegrationContext): Promise<void>;

  handleWebhook(
    req: { method: string; headers: any; body: any; path: string; query: any },
    requestCtx: (uniqueToken: string) => Promise<IntegrationContext>,
    sendResponse: (status: number, body: any) => void // optional, call it only if handling is slow and you want to send a response before it's done
  ): Promise<{
    calendars: Calendar[];
    events: CalendarEvent[];
  } | null>;
}
