import { getAuth, onIdTokenChanged, GoogleAuthProvider, signInWithCredential, linkWithCredential } from 'firebase/auth';
import { getDatabase, ref, update, runTransaction, set, get, onValue } from 'firebase/database';
import {
  getFirestore,
  doc,
  onSnapshot,
  addDoc,
  serverTimestamp,
  collection,
  query,
  limit,
  orderBy,
  setDoc,
  DocumentReference,
} from 'firebase/firestore';
import { useState, useMemo, useEffect, useCallback } from 'react';
import { CalendarEvent, ChatMessage, ChatMessageData, normalizeKey, TaskPredictionSet } from 'shared';
import { nanoid } from 'nanoid';

const db = getDatabase();
const dbFirestore = getFirestore();

export const getCurrentUserUid = () => {
  return getAuth().currentUser.uid;
};

export const listenForUserChange = (cb) => {
  onIdTokenChanged(getAuth(), (user) => {
    console.log('onIdTokenChanged', user);
    cb(user);
  });
};

export const saveToUserProfile = (userId, data) => {
  return update(ref(db, 'users/' + userId), data);
};
export const signInOrLinkWithCredentialAndUpdateProfile = (id_token, existingUser) => {
  var credential = GoogleAuthProvider.credential(id_token);
  console.log('sign in with credential', credential, existingUser);
  return (
    existingUser ? linkWithCredential(existingUser, credential) : signInWithCredential(getAuth(), credential)
  ).then((userCredential) => {
    console.log('Sign in success', userCredential);
    let {
      user,
      // additionalUserInfo
    } = userCredential;
    try {
      // let profile = additionalUserInfo.profile;
      // if (user.displayName !== profile.name || user.email !== profile.email) {
      //   console.log('Update firebase user details from google profile');
      //   return user
      //     .updateProfile({
      //       displayName: profile.name,
      //       photoURL: profile.picture,
      //       email: profile.email,
      //       emailVerified: profile.verified_email,
      //       googleLocale: profile.locale,
      //     })
      //     .then(() => user);
      // }
    } catch (err) {
      console.log('Error updating user profile:', err);
    }
    return user;
  });
};

export const fbSignOut = () => {
  return getAuth().signOut();
};

export let fbOps = {
  getEventById: (userId, eventId): Promise<CalendarEvent | null> => {
    return get(ref(db, `calendar_events/${userId}/${normalizeKey(eventId)}`)).then((snap) => snap.val());
    // return onValue(
    // 	ref(db, `calendar_events/${userId}/${firebase_normalizeKey(eventId)}`),
    //   (snapshot) => snapshot.val(),
    // 	{onlyOnce: true}
    // )
  },
  getEventsByIds: (eventIds: string[]) => {
    const uid = getAuth().currentUser.uid;
    let promises = eventIds.map((eventId) => fbOps.getEventById(uid, eventId));
    return Promise.all(promises);
  },
  partialComplete: (userId, eventId) => {
    if (!userId || !eventId) throw new Error('User ID or eventId not set');
    const eventRef = ref(db, `calendar_events/${userId}/${normalizeKey(eventId)}`);
    return runTransaction(eventRef, (event) => {
      // if (!event) throw new Error(`Event with id ${eventId} is not found`);
      if (!event) {
        console.log('Partial complete transaction: Event with id', eventId, 'is null. Ignore, probably a temp thing');
        return null;
      }
      if (!event.taskId) throw new Error(`Event ${eventId} is not associated with a task`);
      event.completedTaskId = event.taskId;
      event.taskCompleted = true;
      delete event.taskId;
      delete event.tempId;
      return event;
    }).then((results) => {
      console.log('Partial complete transaction result:', results);
      if (results.committed) {
        let event = results.snapshot.val();
        let completedTaskId = event.completedTaskId;
        if (!completedTaskId) throw new Error(`Internal error. completedTaskId is not found for event ${eventId}`);
        let taskPath = `objectives/${userId}/${normalizeKey(completedTaskId)}`;
        let db = getDatabase();
        return update(ref(db, taskPath), {
          eventId: null,
          eventBeginDate: null,
          eventEndDate: null,
        }).then(() => set(ref(db, `${taskPath}/partials/${eventId}`), true));
      }
    });
  },
  completeRecurringEvent: (userId, eventId, taskId) => {
    if (!userId || !eventId || !taskId) {
      console.log('completeRecurringEvent', { userId, eventId, taskId });
      throw new Error('User ID or eventId or taskId not set');
    }
    return update(ref(db, `calendar_events/${userId}/${normalizeKey(eventId)}`), {
      taskId,
      taskCompleted: true,
    });
    // maybe update some dates in task?
  },
  uncompleteRecurringEvent: (userId, eventId, taskId, nextInst = null) => {
    if (!userId || !eventId) throw new Error('User ID or eventId not set');
    return update(ref(db, `calendar_events/${userId}/${normalizeKey(eventId)}`), {
      taskId: null,
      taskCompleted: null,
    }).then(() => {
      if (nextInst) {
        return fbOps.setTaskNextInst(taskId, nextInst);
      }
    });
  },
  breakAssociationByTaskId: (userId, taskId) => {
    return update(ref(db, `objectives/${userId}/${normalizeKey(taskId)}`), {
      eventId: null,
      // eventBeginDate: null,
      // eventEndDate: null,
    });
  },
  breakAllEventAssociationsByTaskId: (userId, taskId) => {
    return update(ref(db, `objectives/${userId}/${normalizeKey(taskId)}`), {
      eventId: null,
      nextInstEventId: null,
      recurringEventIds: null,
    });
  },
  setTaskDuration: (taskId, duration) => {
    const uid = getAuth().currentUser.uid;
    return update(ref(db, `objectives/${uid}/${normalizeKey(taskId)}`), {
      duration,
    });
  },
  setTaskDueDate: (taskId, dueDate) => {
    const uid = getAuth().currentUser.uid;
    return update(ref(db, `objectives/${uid}/${normalizeKey(taskId)}`), {
      dueDate,
    });
  },
  setTaskRecurrence: (taskId, recurrence) => {
    const uid = getAuth().currentUser.uid;
    return update(ref(db, `objectives/${uid}/${normalizeKey(taskId)}`), {
      recurrence,
    });
  },
  setTaskNextInst: async (taskId, nextInst) => {
    const uid = getAuth().currentUser.uid;
    const { nextInstEventId, eventBeginDate, eventEndDate } = nextInst;
    if (!eventBeginDate || !eventEndDate) throw new Error('eventBeginDate or eventEndDate is not set');
    const data = {
      nextInstEventId,
      eventBeginDate,
      eventEndDate,
    };
    await update(ref(db, `objectives/${uid}/${normalizeKey(taskId)}`), data);
    return data;
  },
  clearTaskCompletedFix: async (taskId) => {
    const uid = getAuth().currentUser.uid;
    const data = {
      completed: null,
      completedAutoFix: true,
    };
    await update(ref(db, `objectives/${uid}/${normalizeKey(taskId)}`), data);
    return data;
  },
  setTaskScheduleQueue: (taskId: string, scheduleQueue: boolean) => {
    const uid = getAuth().currentUser.uid;
    return update(ref(db, `objectives/${uid}/${normalizeKey(taskId)}`), {
      queue: scheduleQueue,
    });
  },
  setListScheduleQueue: (listId: string, scheduleQueue: boolean) => {
    const uid = getAuth().currentUser.uid;
    return update(ref(db, `lists/${uid}/${normalizeKey(listId)}`), {
      queue: scheduleQueue,
    });
  },
  disconnectTodoist: (userId) => {
    let tasksRef = ref(db, `objectives/${userId}`);
    let listsRef = ref(db, `lists/${userId}`);
    return Promise.all([get(tasksRef).then((snap) => snap.val()), get(listsRef).then((snap) => snap.val())]).then(
      ([tasks, lists]) => {
        if (!tasks) return;
        const { listsIdsToPurge, tasksIdsToPurge, eventIdsToRemoveAssociation } = extractTodoistDataToPurge(
          tasks || {},
          lists || {}
        );
        // console.log('tasksIdsToPurge', tasksIdsToPurge)
        // console.log('eventIdsToRemoveAssociation', eventIdsToRemoveAssociation)
        // console.log('listsIdsToPurge', listsIdsToPurge)

        let dataToPurge = generateFirebasePurgeData(
          userId,
          listsIdsToPurge,
          tasksIdsToPurge,
          eventIdsToRemoveAssociation
        );
        console.log('Purge todoist data', JSON.stringify(dataToPurge));
        return update(ref(db), dataToPurge);
      }
    );
  },
  voteIntegration: (userId, integration) => {
    let data = {};
    data[normalizeKey(integration)] = true;
    return update(ref(db, 'users/' + userId + '/votes'), data);
  },
  reorderLists: (userId, lists, newIdxList) => {
    // [0,1,2,3,4,5]
    // [5,0,1,2,3,4] <- 6th moved to the beginning
    console.debug('Reorder lists:', lists, newIdxList);
    const data = {};
    newIdxList.forEach((oldIndex, newIndex) => {
      const list = lists[oldIndex];
      // console.log('list', list.name, list.id, 'old order:', list.order, oldIndex, 'new order:', newIndex)
      data[`lists/${userId}/${normalizeKey(list.id)}/order`] = newIndex;
    });
    console.debug('Reorder lists, data to apply:', data, userId);
    return update(ref(db), data);
  },
};

export const useFirebaseReadOnce = (path) => {
  const [result, setResult] = useState();
  const [error, setError] = useState();

  useEffect(() => {
    const dbRef = ref(db, path);
    get(dbRef)
      .then((snap) => {
        setResult(snap.val());
      })
      .catch((err) => {
        setError(err);
        setResult(null);
      });
  }, [setResult, setError, path]);

  return useMemo(() => ({ data: result, loading: result === undefined, error }), [result, error]);
};

export const useFirebaseWatch = <T extends any>(path, skip = false) => {
  const [result, setResult] = useState<T>();
  const [error, setError] = useState();

  useEffect(() => {
    if (skip) {
      setResult(null);
      return;
    }
    const dbRef = ref(db, path);
    return onValue(
      dbRef,
      (snap) => {
        setResult(snap.val());
      },
      (err: any) => {
        setError(err);
        setResult(null);
      }
    );
  }, [setResult, setError, skip, path]);

  const updateData = useCallback(
    (newData) => {
      console.log('updateData', path, newData);
      const dbRef = ref(db, path);
      return update(dbRef, newData);
    },
    [path]
  );

  return useMemo(
    () => ({ data: result, loading: result === undefined, error, update: updateData }),
    [result, error, updateData]
  );
};

export const useTrainingStatusWatch = () => {
  const [result, setResult] = useState<any>();
  const [error, setError] = useState();

  useEffect(() => {
    const uid = getAuth().currentUser.uid;
    return onSnapshot(
      doc(dbFirestore, 'user_models_training_requests', uid),
      (doc) => {
        setResult(doc.data());
      },
      (err: any) => {
        setError(err);
        setResult(null);
      }
    );
  }, [setResult, setError]);

  return useMemo(() => ({ data: result, loading: result === undefined, error }), [result, error]);
};

export const useWatchTaskPredictions = (taskId, props = { skip: false }) => {
  const uid = getAuth().currentUser.uid;
  return useFirebaseWatch<TaskPredictionSet>(`predictions/${uid}/${normalizeKey(taskId)}`, props.skip);
};

export const getCachedTaskPredictions = (taskId): Promise<TaskPredictionSet | null> => {
  const uid = getAuth().currentUser.uid;
  const pathRef = ref(db, `predictions/${uid}/${normalizeKey(taskId)}`);
  return new Promise<TaskPredictionSet | null>((resolve, reject) => {
    onValue(
      pathRef,
      (snap) => {
        resolve(snap.val());
      },
      reject,
      {
        onlyOnce: true,
      }
    );
  });
};

export const useChatMessages = () => {
  const [result, setResult] = useState<ChatMessage[]>();
  const [error, setError] = useState();

  const uid = getAuth().currentUser.uid;

  const chatDoc = useMemo(() => doc(dbFirestore, 'chats', uid), [uid]);
  const conversationCollection = useMemo(() => collection(chatDoc, 'conversations'), [chatDoc]);

  const [conversationDoc, setConversationDoc] = useState<DocumentReference | null>();
  useEffect(() => {
    const convQuery = query(conversationCollection, orderBy('lastModified', 'desc'), limit(1));

    return onSnapshot(
      convQuery,
      (snap) => {
        console.log('conversation onSnapshot', snap);
        console.log('conversation onSnapshot data', snap.docs);
        const convDoc = snap.docs?.[0];
        const convId = convDoc?.id || nanoid();
        setConversationDoc(doc(conversationCollection, convId));
      },
      (err: any) => {
        console.log('conversation onSnapshot error', err);
        setError(err);
        setResult(null);
      }
    );
  }, [setResult, setError, conversationCollection]);

  const messagesCollection = useMemo(
    () => (conversationDoc ? collection(conversationDoc, 'messages') : null),
    [conversationDoc]
  );

  useEffect(() => {
    if (!messagesCollection) return;

    const msgQuery = query(messagesCollection, orderBy('ts'), limit(200));

    return onSnapshot(
      msgQuery,
      (snap) => {
        console.log('chat onSnapshot', snap);
        console.log('chat onSnapshot data', snap.docs);
        setResult(snap.docs?.map((msgDoc) => ({ ...msgDoc.data(), id: msgDoc.id } as ChatMessage)));
      },
      (err: any) => {
        console.log('chat onSnapshot error', err);
        setError(err);
        setResult(null);
      }
    );
  }, [setResult, setError, messagesCollection]);

  return useMemo(() => {
    const pushMessage = async (data: ChatMessageData) => {
      if (!messagesCollection || !conversationDoc) {
        console.error('messagesCollection is not set');
        return;
      }

      await addDoc(messagesCollection, {
        ts: serverTimestamp(),
        ...data,
      });

      try {
        await setDoc(conversationDoc, {
          lastModified: serverTimestamp(),
        });
      } catch (err) {
        console.error('Error setting timestamp', err);
      }
    };

    const newConversation = async (): Promise<string> => {
      if (!conversationCollection) {
        console.error('conversationCollection is not set');
        return;
      }

      const conversationDoc = doc(conversationCollection, nanoid());
      setConversationDoc(conversationDoc);

      await setDoc(conversationDoc, {
        lastModified: serverTimestamp(),
      });

      return conversationDoc.id;
    };

    return {
      data: result,
      loading: result === undefined,
      error,
      conversationId: conversationDoc?.id,
      pushMessage,
      newConversation,
    };
  }, [result, error, messagesCollection, conversationDoc, conversationCollection]);
};

export function createAssociation(
  userId: string,
  taskId: string,
  eventId: string,
  eventBeginDate: string,
  eventEndDate: string,
  recurringEventId?: string
) {
  console.log('fb createAssociation', userId, taskId, eventId, eventBeginDate, eventEndDate);
  if (!userId || !taskId || !eventId)
    throw new Error('Cannot create association - one of mandatory parameters (userId, taskId, eventId) missing');

  return Promise.all([
    update(ref(db, `calendar_events/${userId}/${normalizeKey(eventId)}`), recurringEventId ? {} : { taskId }),
    update(ref(db, `objectives/${userId}/${normalizeKey(taskId)}`), {
      eventId: recurringEventId ? null : eventId,
      eventBeginDate,
      eventEndDate,
      [`recurringEventIds/${recurringEventId || '_'}`]: recurringEventId ? true : null,
    }),
  ]);
}

const todoistRegex = /@TrevorOrig:2$/;
const isTodoist = (key) => key.match(todoistRegex);

export function extractTodoistDataToPurge(tasksObj, listsObj) {
  let tasksIdsToPurge = [],
    eventIdsToRemoveAssociation = [],
    listsIdsToPurge = [];

  for (let key in tasksObj) {
    const task = tasksObj[key];
    if (isTodoist(key)) {
      tasksIdsToPurge.push(key);
      if (task.eventId) {
        eventIdsToRemoveAssociation.push(task.eventId);
      }
    } else {
      console.log('Not todoist task id', key);
    }
  }
  for (let key in listsObj) {
    if (isTodoist(key)) {
      listsIdsToPurge.push(key);
    } else {
      console.log('Not todoist list', key);
    }
  }

  return {
    listsIdsToPurge,
    tasksIdsToPurge,
    eventIdsToRemoveAssociation,
  };
}

export function generateFirebasePurgeData(userId, listsIdsToPurge, tasksIdsToPurge, eventIdsToRemoveAssociation) {
  let dataToPurge = {
    [`users/${userId}/todoistToken`]: null,
    [`users/${userId}/todoistSyncToken`]: null,
    [`users/${userId}/todoistUserId`]: null,
  };
  listsIdsToPurge.forEach((itemId) => (dataToPurge[`lists/${userId}/${itemId}`] = null));
  tasksIdsToPurge.forEach((itemId) => (dataToPurge[`objectives/${userId}/${itemId}`] = null));
  eventIdsToRemoveAssociation.forEach((itemId) => {
    dataToPurge[`calendar_events/${userId}/${itemId}/taskId`] = null;
    dataToPurge[`calendar_events/${userId}/${itemId}/taskCompleted`] = null;
  });
  return dataToPurge;
}
