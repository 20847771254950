import moment from 'moment';
import { ChatActionNewTaskEvent, ChatActionTaskEvent, ChatMessageRichData, User, UserReadonlyDetails } from './types';

// we're trying to make the external keys safe for firebase, while making sure there's no loss of data
// and possibility for collision - that is 2 different ids turning into same key after normalization
//
// https://firebase.google.com/docs/database/admin/structure-data#how_data_is_structured_its_a_json_tree
// If you create your own keys, they must be UTF-8 encoded, can be a maximum of 768 bytes, and cannot
// contain ., $, #, [, ], /, or ASCII control characters 0-31 or 127. You cannot use ASCII control
// characters in the values themselves, either.
const reKey = /[^a-zA-Z0-9@_\-=:]/g;
export const normalizeKey = (key: string) => {
  // export const new_normalizeKey = (key: string) => {
  return key.replace(reKey, (c) => '%' + c.codePointAt(0).toString(16).toUpperCase());
};

export const legacy_normalizeKey = (key: string) => {
  // export const normalizeKey = (key: string) => {
  return key.replace(/[^a-zA-Z0-9@_]/g, '_');
};

export const taskTitleWithList = (title: string, listName: string) => {
  return `${listName}: ${title}`;
};

export interface ParseChatRichMessageResult {
  data: ChatMessageRichData;
  isTextOnly: boolean;
  isDocsOnly: boolean;
  isGetOnly: boolean;
  hasActions: boolean;
  isInvalid: boolean;
}

export const parseChatRichMessage = (message: string, validFields?: string[]): ParseChatRichMessageResult => {
  let data: ChatMessageRichData = {
    text: message,
  };

  try {
    data = JSON.parse(message);
    if (typeof data !== 'object') {
      console.error('Invalid rich message', message);
      data = {
        text: message,
      };
    }
  } catch (e) {}

  const itemsCount = Object.keys(data).length;
  const isTextOnly = typeof data.text === 'string' && itemsCount === 1;
  const isDocsOnly = !!data.docs && itemsCount === 1;
  const isGetOnly = !!data.get && itemsCount === 1;
  const hasActions = !isTextOnly;

  const isInvalid = !validFields || Object.keys(data).some((key) => !validFields.includes(key));

  return {
    data,
    isTextOnly,
    isDocsOnly,
    isGetOnly,
    hasActions,
    isInvalid,
  };
};

const pad = (num: number) => (num < 10 ? `0${num}` : num);
const reLocalWithoutTimezone = /^(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2})$/;

export const localToUTC = (date: string, timezoneOffset: number): moment.Moment => {
  if (!reLocalWithoutTimezone.test(date)) return moment(date);

  const offsetHour = Math.floor(timezoneOffset / 60);
  const offsetMin = timezoneOffset % 60;
  const offsetHourMinStr = `${offsetHour >= 0 ? '+' : '-'}${pad(Math.abs(offsetHour))}:${pad(Math.abs(offsetMin))}`;
  return moment(date + offsetHourMinStr);
};

export const encrichWithEndDate = (events: (ChatActionNewTaskEvent | ChatActionTaskEvent)[]) => {
  return events.map((event) => {
    const { beginDate, endDate, allDay, duration } = event;
    if (!beginDate || endDate) return event;
    if (allDay || !duration) {
      return { ...event, allDay: true, endDate: moment(beginDate).add(1, 'day').toISOString() };
    }
    return {
      ...event,
      allDay: allDay || duration >= 1440,
      endDate: moment(beginDate).add(duration, 'minutes').toISOString(),
    };
  });
};

export const convertDatesToUTC = <T extends { beginDate: string; endDate?: string }>(
  events: T[],
  timezoneOffset: number
): T[] => {
  return events.map((event) => {
    const { beginDate } = event;
    if (!beginDate) return event;
    const res = { ...event, beginDate: localToUTC(beginDate, +timezoneOffset).toISOString() };
    if (event.endDate) {
      res.endDate = localToUTC(event.endDate, +timezoneOffset).toISOString();
    }
    return res;
  });
};

export const ChatSystemInfoDelimiter = '---';

export const toBatches = <T>(arr: T[], chunkSize: number): T[][] => {
  let res = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    res.push(arr.slice(i, i + chunkSize));
  }
  // console.log('toChunks', arr.length, chunkSize, res.length);
  return res;
};

export const isPremium = (user: UserReadonlyDetails | User) => {
  let { premiumUntil, premiumPlan } = user;
  if (premiumPlan === 'patreon') return true;
  if (!premiumPlan) return true; // Early bird
  if (
    !premiumUntil
    // premiumPlan free with premiumUntil means No-card Trial
    //  || premiumPlan === 'free'
  )
    return false;

  let date = moment().format('YYYY-MM-DD');
  return date <= premiumUntil;
};
