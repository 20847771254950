// import 'babel-polyfill'
import { all } from 'redux-saga/effects';
import tasksSaga from './tasks.jsx';
import calendarSaga from './calendar';
import notificationService from './notifications.jsx';
import analyticsService from './analytics.jsx';
import walkthrough from './walkthrough.jsx';
import settingsSaga from './settings';
import mainSaga from './main.jsx';
import extensionsSaga from './extensions';

// single entry point to start all Sagas at once
export default function* rootSaga() {
  yield all([
    tasksSaga(),
    calendarSaga(),
    notificationService(),
    analyticsService(),
    walkthrough(),
    settingsSaga(),
    mainSaga(),
    extensionsSaga(),
  ]);
}
