import moment from 'moment';
import type { List, Task, TaskPredictionSet, UserPrefs, UserScheduleConfig } from '../types';
import { PlanningContext } from './types';

export const userSchedule247: UserScheduleConfig = {
  days: [1, 1, 1, 1, 1, 1, 1],
  zones: [{ start: 0, end: 24 * 60 }],
};

export const userScheduleDefault: UserScheduleConfig = {
  days: [0, 1, 1, 1, 1, 1, 0], // 0 - false, 1 - true, in future could also be object with custom zones for the day
  zones: [{ start: 9 * 60, end: 19 * 60 }],
};

export const defaultPrefs: UserPrefs = {
  default_task_duration: 60,
  add_task_nlp: true,
  due_date_is_exact_date: false,
  recurring_due_date_is_exact_date: true,
  due_date_in_scheduling_queue: true,
  max_all_day_events: 3,
  overdue_suggestions_limit: 3,
  inbox_suggestions_limit: 3,
  duration_predictions: true,
  start_of_day: 9 * 60,
  user_schedule: userScheduleDefault,
};

export const isInUserSchedule = (ctx: PlanningContext, date: string | moment.Moment) => {
  const {
    momenttz,
    prefs: { user_schedule },
  } = ctx;
  const m = momenttz(date);
  const day = m.day();
  const minutes = m.hours() * 60 + m.minutes();

  const daySchedule = user_schedule.days[day];
  if (!daySchedule) return false;

  const zone = user_schedule.zones.find((z) => z.start <= minutes && minutes < z.end);
  if (!zone) return false;

  return true;
};

export const generateDatesRange = (
  ctx: PlanningContext,
  fromDate: moment.Moment | string,
  toDate: moment.Moment | string,
  customUserSchedule?: UserScheduleConfig
) => {
  const {
    momenttz,
    prefs: { user_schedule },
  } = ctx;
  let date = momenttz(fromDate);
  let endDate = toDate;
  if (!date.isSame(toDate) && momenttz(toDate).startOf('day').isSame(toDate)) {
    endDate = momenttz(toDate).subtract(1, 'day');
  }
  const range: (typeof date)[] = [];
  for (; date.isSameOrBefore(endDate, 'day'); date = moment(date).add(1, 'day')) {
    if (!!(customUserSchedule ?? user_schedule).days[date.day()]) {
      range.push(date.clone());
    }
  }
  return range;
};

export const getDatesFromAllDayEvent = (ctx: PlanningContext, event: { beginDate: string; endDate: string }) => {
  const { momenttz } = ctx;
  const beginDate = momenttz(event.beginDate);
  const endDate = momenttz(event.endDate);
  const dates = [];
  for (let date = beginDate.clone(); date.isBefore(endDate, 'day'); date.add(1, 'day')) {
    dates.push(date.clone().format('YYYY-MM-DD'));
  }
  return dates;
};

export const MinAllDayDuration = 24 * 60;

const reDuration = /^(\d+)(m|min|h|d)$/;
const reHourMinDuration = /^(\d+)h (\d+)(m|min)$/;
export const extractTodoistDuration = (task: Task) => {
  const duration = task.todoist?.duration;
  if (duration) {
    const unit = duration.unit; //minute or day
    const value = duration.amount;
    if (unit === 'minute') {
      return value;
    } else if (unit === 'day') {
      return value * 1440;
    }
  }

  const labels = task.todoist?.labels;
  if (!labels?.length) return null;

  for (const label of labels) {
    const matchHM = String(label).match(reHourMinDuration);
    if (matchHM) {
      const hours = parseInt(matchHM[1]);
      const minutes = parseInt(matchHM[2]);
      return hours * 60 + minutes;
    }

    const match = String(label).match(reDuration);
    if (match) {
      let duration = parseInt(match[1]);
      const unit = match[2];
      if (unit === 'h') {
        duration *= 60;
      } else if (unit === 'd') {
        duration *= 1440;
      }
      return duration;
    }
  }
  return null;
};

export const taskDuration = (task: Task, defaultTaskDuration: number, pred: TaskPredictionSet) => {
  const eventDuration = task.eventId ? moment(task.eventEndDate).diff(task.eventBeginDate, 'minutes') : null;
  let duration = eventDuration || task.duration || extractTodoistDuration(task) || pred.duration?.[0]?.value || 60;
  // if (duration >= MinAllDayDuration) {
  //   duration = defaultTaskDuration;
  // }
  return duration;
};

export const generatePredictionBoostFromHint = (hintDatetime: moment.Moment, prob = 75) => {
  const dt = hintDatetime;
  const predictionBoost = {
    days: { value: dt.format('ddd'), prob },
    start_times: { value: dt.hour() * 60 + dt.minute(), prob },
  };
  return predictionBoost;
};

export const suggestionsOverlap = (sugg1: moment.Moment, sugg2: moment.Moment, duration: number) => {
  return Math.abs(moment(sugg1).diff(sugg2, 'minutes')) < duration;
};

export const wrapToTimeGrid = (minutes: number, gridMinutes: number = 15) => gridMinutes - (minutes % gridMinutes);

export const momentToFloat = (m: moment.Moment) => m.hour() + m.minute() / 60;

export const inSchedulingQueue = (ctx: PlanningContext, task: Task, listsObj: Record<string, List>) => {
  const list = listsObj?.[task.listId] || ({} as List);
  return (
    (task.queue === true || (ctx.prefs.due_date_in_scheduling_queue && !!task.dueDate) || list.queue) &&
    list.enabled !== false &&
    list.removed !== true
  );
  // if (!task || task.completed || task.eventId || task.nextInstEventId) return false; // Ignore completed and scheduled tasks
  // return false;
};

export const formatDate = (date: string | moment.Moment | Date) => moment(date).format('YYYY-MM-DD');

export const formatHumanDate = (date: string | moment.Moment, allDay = false) => {
  return moment(date).calendar(
    !allDay
      ? {
          lastDay: '[Yesterday,] LT',
          sameDay: '[Today,] LT',
          nextDay: '[Tomorrow,] LT',
          lastWeek: '[last] dddd [at] LT',
          nextWeek: 'dddd [at] LT',
          sameElse: 'ddd, l [at] LT',
        }
      : {
          lastDay: '[Yesterday]',
          sameDay: '[Today]',
          nextDay: '[Tomorrow]',
          lastWeek: '[last] dddd',
          nextWeek: 'dddd',
          sameElse: 'ddd, l',
        }
  );
};

export const isDueDateWithTime = (dueDate: string): string | null => {
  const pieces = dueDate.split('T');
  return pieces[1] || null;
};

export const isScheduledDateMismatchDueDate = (task: Task, isExactDate: boolean) => {
  const { dueDate, eventId, eventBeginDate } = task;
  if (!dueDate || !eventId || !eventBeginDate) return false;

  const start = moment(eventBeginDate);
  const due = moment(dueDate);

  const hasDueTime = isDueDateWithTime(dueDate);
  if (hasDueTime && !start.isSame(due)) {
    return true;
  }
  if (isExactDate && !start.isSame(due, 'day')) {
    return true;
  }
  if (start.isAfter(due, 'day')) {
    return true;
  }
  return false;
};

const reGetEventProvider = /@[^@]+$/;

export const detectProvider = (id: string): string => {
  const res = id.match(reGetEventProvider);
  // if (!res) throw new Error('Cannot determine event provider from id');
  if (!res) {
    // in some cases it's created without provider
    return 'trevorai';
  }
  const [atProvider] = res;
  return atProvider.substring(1);
};

export const generateEventIdWithProvider = (provider: string) =>
  `trevorai${Math.random().toString().slice(2)}gg${Date.now()}@${provider}`;

export const isRecurringTask = (task: Task) => task.isRecurring || !!task.recurrence || task.todoist?.due?.is_recurring;

export const resolveTaskTitleWithParents = (task: Task, tasksObj: Record<string, Task>, pathOnly = false): string => {
  if (!task.parent_id) return pathOnly ? '' : task.title;

  const titleParts = [];
  let currentTask = task;
  while (currentTask) {
    titleParts.unshift(currentTask.title);
    currentTask =
      tasksObj[currentTask.parent_id] ||
      (currentTask.todoist ? tasksObj[currentTask.parent_id + '@TrevorOrig:2'] : null);
  }
  if (pathOnly && titleParts.length > 1) {
    titleParts.pop();
    return titleParts.join(' / ') + ' /';
  }
  return titleParts.join(' / ');
};
