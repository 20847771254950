import React, { useEffect, useState } from 'react';
import * as scriptjs from 'scriptjs';
import { Button } from '@mui/material';
import Analytics from '../analytics.jsx';
import { premiumIntelligence } from '../operations/premiumIntel.jsx';
import { preactivateSubscription } from '../api/payment';

const { REACT_APP_PADDLE_ID, REACT_APP_PADDLE_PLANS, REACT_APP_PADDLE_MODE } = process.env;

const isDevelopment = REACT_APP_PADDLE_MODE === 'development';

const plansIds = REACT_APP_PADDLE_PLANS ? JSON.parse(REACT_APP_PADDLE_PLANS) : {};

export const PaddleCheckout = ({ open, plan, title, onClose, onSubcriptionCreated, onSpecialPrice, data }) => {
  console.log('PaddleCheckout', { open, plan, title, onClose, onSubcriptionCreated, onSpecialPrice, data });
  const [paddleSdk, setPaddleSdk] = useState(null);
  Analytics.tagManagerEvent({
    event: 'conversion_checkout_initiated_' + plan,
    userEmail: data.user.email || null,
  });
  useEffect(() => {
    scriptjs('https://cdn.paddle.com/paddle/paddle.js', () => {
      const Paddle = window.Paddle;
      // console.log('Paddle loaded', Paddle);
      if (isDevelopment) {
        console.log('Paddle Sandbox');
        Paddle.Environment.set('sandbox');
      }
      Paddle.Setup({
        vendor: parseInt(REACT_APP_PADDLE_ID),
        eventCallback: (e) => {
          console.log('Paddle callback', e);
          if (e.event === 'Checkout.Location.Submit') {
            Analytics.event({ category: 'Premium', action: 'Paddle: Submitted Location', label: e.eventData.country });
          } else if (e.event === 'Checkout.Coupon.Submit') {
            Analytics.event({
              category: 'Premium',
              action: 'Paddle: Submitted Coupon',
              label: e.eventData.coupon.coupon_code,
            });
          } else if (e.event === 'Checkout.Coupon.Applied') {
            Analytics.event({
              category: 'Premium',
              action: 'Paddle: Applied Coupon',
              label: e.eventData.coupon.coupon_code,
            });
            Analytics.tagManagerEvent({
              event: 'conversion_coupon_applied',
              userEmail: data.user.email || null,
            });
            const price = e.eventData?.checkout?.recurring_prices?.vendor?.total;
            if (price) onSpecialPrice?.(price);
          } else if (e.event === 'Checkout.Coupon.Remove') {
            Analytics.event({
              category: 'Premium',
              action: 'Paddle: Removed Coupon',
              label: e.eventData.coupon.coupon_code,
            });
            onSpecialPrice?.(null);
          } else if (e.event === 'Checkout.Vat.Applied') {
            Analytics.event({ category: 'Premium', action: 'Paddle: Submitted VAT ID' });
          } else if (e.event === 'Checkout.PaymentMethodSelected') {
            Analytics.event({
              category: 'Premium',
              action: 'Paddle: Selected Payment Method: ' + e.eventData.payment_method,
            });
          } else if (e.event === 'Checkout.Error') {
            Analytics.event({
              category: 'Premium',
              action: 'Paddle: Checkout Failed: ' + e.eventData.error_type,
              label: e.eventData.error,
            });
          } else if (e.event === 'Checkout.PaymentComplete') {
            Analytics.event({
              category: 'Premium',
              action: 'Paddle: Payment Successfull with ' + e.eventData.payment_method,
            });
          } else if (e.event === 'Checkout.Complete') {
            Analytics.event({
              category: 'Premium',
              action: 'Paddle: Subscribed for ' + e.eventData.product.name + ', id: ' + e.eventData.product.id,
            });
            Analytics.tagManagerEvent({ event: 'conversion_trial', userEmail: data.user.email || null });
            Analytics.tagManagerEvent({
              event: 'conversion_checkout_completed_' + plan,
              userEmail: data.user.email || null,
            });
            console.log('Conversion Tracked: conversion_trial'); // This does not indicate a successful trial, but it's the best we can do on the client-side.
            onSubcriptionCreated();

            const analyticsData = premiumIntelligence(data, e.eventData);

            setTimeout(async () => {
              // doing it immediately fails because the subscription state is "processing" - give them a few moments
              try {
                await preactivateSubscription(e.eventData.checkout.id, analyticsData);
              } catch (err) {
                console.log('Error pre-activating subscription:', err);
              }
            }, 3000);

            setTimeout(() => {
              onClose();
            }, 5000);
          }
        },
      });
      console.log('Set paddle sdk', Paddle);
      setPaddleSdk(Paddle);
    });
  }, [setPaddleSdk]);

  useEffect(() => {
    // console.log('Paddle plans', JSON.stringify(plansIds))
    if (!paddleSdk || !open || !plan) return;

    const params = {
      product: plansIds[plan],
      email: data.user.email,
      passthrough: data.user.uid,
      method: 'inline',
      allowQuantity: false,
      disableLogout: true,
      frameTarget: 'checkout-container',
      frameInitialHeight: 416,
      frameStyle: 'width:100%; min-width:312px; background-color: transparent; border: none;',
    };
    console.log('Open with params', params);

    paddleSdk.Checkout.open(params);
  }, [paddleSdk, open]);

  return (
    <div style={{ display: open ? undefined : 'none' }}>
      <h1 style={{ textAlign: 'center', marginTop: '50px' }}>{title}</h1>
      <div style={{ maxWidth: 520, margin: '0 auto' }}>
        <div
          className="checkout-container"
          style={{ minHeight: 445, background: 'white', borderRadius: '28px', overflowY: 'scroll', padding: '30px' }}
        />
        <div style={{ textAlign: 'center' }}>
          {/* <p>You will be charged after the 7 day free trial.</p> */}
          <p>
            You are purchasing a <strong>recurring subscription</strong> for Trevor AI that renews automatically.
            Payment details are securely and exclusively handled by Paddle™. For payment questions, team plans or
            issues, please contact us at <a href="mailto:support@trevorlabs.com">support@trevorlabs.com</a>.
          </p>
        </div>
      </div>
      <Button
        variant="text"
        color="info"
        fullWidth
        onClick={() => onClose && onClose()}
        sx={{
          textTransform: 'none',
          borderRadius: '26px',
          color: 'black',
          border: '1px solid rgba(200,200,200)',
        }}
      >
        Back
      </Button>
    </div>
  );
};
