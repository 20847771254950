import { Component, useEffect, useState } from 'react';
import {
  // Card, CardActions, CardHeader, CardMedia, CardTitle, CardText,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Stack,
  TextField,
  Grid,
  Box,
  Alert,
  AlertTitle,
  DialogActions,
  Link,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableCell,
  TableRow,
} from '@mui/material';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdatesOutlined';
import CloudSyncIcon from '@mui/icons-material/CloudSyncOutlined';
import InsightsIcon from '@mui/icons-material/InsightsOutlined';
import SupportIcon from '@mui/icons-material/ContactSupportOutlined';
import StarIcon from '@mui/icons-material/Star';
import XIcon from '@mui/icons-material/Close';
import LinkIcon from '@mui/icons-material/Link';
import {
  Event,
  TrendingUp,
  Chat,
  AssignmentInd,
  ContentPaste,
  Check,
  EditCalendar,
  TouchApp,
  AddLink,
} from '@mui/icons-material';
import './premium.scss';
import { saveModule, savePremiumContext } from './api/account';
import moment from 'moment';
import Analytics from './analytics.jsx';
import BackToHomeButton from './components/BackToHomeButton.jsx';
import { PaddleCheckout } from './components/PaddleCheckout.jsx';
import { SmartSchedulingIcon } from './components/CustomIcons.jsx';
import { saveToUserProfile, useFirebaseReadOnce } from './common/firebase';
import { styled } from '@mui/system';
import { theme } from './theme';
import { premiumModules, isPremium } from 'shared';
import { planPrice, planTitle, usePremium } from 'common/usePremium';
const { confirm } = window;

// const MD4 = ({ children }) => (
//   <div className="col-xs-12 col-sm-12 col-md-4" style={{ padding: '0 40px' }}>
//     {children}
//   </div>
// );

const RedButton = ({ children, ...props }) => (
  <Button
    variant="text"
    fullWidth
    // className="upgrade-module-pricing"
    sx={{ color: 'red', backgroundColor: 'white', '&:hover': { backgroundColor: '#eee' } }}
    {...props}
  >
    {children}
  </Button>
);

const BasicButton = styled(Button)(({ theme }) => ({
  color: 'black !important', // overrides clicked link color
  textTransform: 'none',
  fontSize: '0.95em',
  borderRadius: '26px',
  background: 'white !important',
  border: '1px solid rgba(0,0,0,0.15)',
  boxShadow: '0px 0px 10px rgba(0,0,0,0)',
  transition: 'box-shadow 0.3s ease-in-out',
  '&:hover': {
    boxShadow: '0px 0px 15px rgba(0,0,0,0.25)',
  },
}));

const PrimaryButton = ({ children, ...props }) => (
  <Button
    variant="contained"
    // color="primary"
    fullWidth
    // className="upgrade-module-pricing"
    {...props}
    sx={{
      textTransform: 'none',
      fontSize: '0.95em',
      color: 'white !important', // !important overrides buttons with hrefs
      background: (theme: any) => theme.palette.gradient.premiumBlue,
      borderRadius: '26px',
      boxShadow: '0px 0px 10px rgba(0,0,0,0)',
      transition: 'box-shadow 0.3s ease-in-out',
      '&:hover': {
        boxShadow: '0px 0px 15px rgba(0,0,0,0.25)',
      },
    }}
  >
    {children}
  </Button>
);

const LinkButton = ({ children, ...props }) => (
  <BasicButton variant="contained" fullWidth {...props}>
    {children}
  </BasicButton>
);

const SecondaryButton = ({ children, ...props }) => (
  <BasicButton variant="contained" fullWidth {...props}>
    {children}
  </BasicButton>
);

const CloseButton = ({ onClick }) => (
  <BasicButton fullWidth onClick={onClick}>
    Close
  </BasicButton>
);

const PatreonButton = () => (
  <BasicButton
    fullWidth
    onClick={() => {
      window.open('http://patreon.com/trevor_AI', '_blank');
      Analytics.event({ category: 'Premium', action: 'Initiated ' + 'Go Pro with Patreon' });
    }}
    style={{ maxWidth: '380px' }}
  >
    Go Pro with Patreon
  </BasicButton>
);

const SpecialCodeButton = ({ onClick }) => (
  <BasicButton fullWidth onClick={onClick} style={{ maxWidth: '380px' }}>
    Apply Special Code
  </BasicButton>
);

const CloseIcon = ({ onClick }) => (
  <XIcon
    onClick={onClick}
    sx={{
      position: 'absolute',
      top: '25px',
      right: '25px',
      '&:hover': { cursor: 'pointer', color: 'rgb(80,150,190)' },
    }}
  />
);

// const totalModules = premiumModules.length;

// class Premium extends Component {
//   state = {
//     makePayment: false,
//   };

//   render() {
//     const { user, onActivateFreeTrial, onToggleModule } = this.props;
//     let onFreeTrial, onUpgrade, activeUntil, onCancelSubscription;
//     let userIsPremium = isPremium(user);
//     // userIsPremium = true // TESTING
//     const activeModulesObj = user.modules || {};
//     const isModuleActive = (module) => !!activeModulesObj[module.id];
//     let { activeSubscriptionId } = user;
//     // console.log({ activeSubscriptionId, ss: user.subscriptions })
//     const expired = moment().isAfter(user.premiumUntil, 'day');

//     Analytics.event({
//       category: 'Premium',
//       action: 'Browsing Upgrade Modules',
//     });

//     if (userIsPremium) {
//       activeUntil = user.premiumUntil;

//       if (activeSubscriptionId) {
//         onCancelSubscription = async () => {
//           try {
//             await cancelSubscription(activeSubscriptionId);
//             Analytics.event({
//               category: 'Premium',
//               action: user.premiumPlan + ' subscription Cancelled',
//             });
//             Notification.show('Subscription cancelled');
//           } catch (err) {
//             console.log('Error cancelling subscription', err);
//             Analytics.event({
//               category: 'Premium',
//               action: 'Error cancelling subscription',
//             });
//             Notification.showError('Error cancelling subscription. Please contact us');
//           }
//         };
//       }
//     } else {
//       if (user.premiumUntil) {
//         onUpgrade = () => {
//           this.setState({ makePayment: true });
//         };
//       } else {
//         onFreeTrial = async () => {
//           try {
//             await onActivateFreeTrial();
//             Notification.show('Free trial activated');
//             console.log('Free trial activated');
//           } catch (err) {
//             console.log('error activating free trial', err);
//             Notification.showError('Cannot activate free trial. Please try again');
//           }
//         };
//       }
//     }

//     let availableModules = premiumModules.map((module) => {
//       return (
//         <MD4 key={module.title}>
//           {renderUpgradeModule(module, userIsPremium, isModuleActive(module), (active) =>
//             onToggleModule(module.id, active)
//           )}
//         </MD4>
//       );
//     });

//     return (
//       <PageBox id="upgrades" sx={{ backgroundColor: 'white' }}>
//         <PageNav direction="row" spacing={3}>
//           <BackToHomeButton />
//           <h2>
//             {!expired ? 'Active plan' : 'Expired plan'}: <strong>{planTitle(user.premiumPlan)}</strong>
//           </h2>
//           <PremiumButton
//             link
//             label={
//               user.premiumPlan && user.premiumPlan != 'free'
//                 ? expired
//                   ? 'Renew Plan'
//                   : 'Manage Plan'
//                 : 'Upgrade to Pro'
//             }
//           />
//         </PageNav>
//         <PageScroll>
//           <h1 style={{ textAlign: 'center' }}>Available Upgrades for your Trevor</h1>
//           <div style={{ textAlign: 'center', marginBottom: '30px' }}>
//             {!userIsPremium && (
//               <>
//                 <p style={{ textAlign: 'center', display: 'inline' }}>Enable all available upgrades with a </p>
//                 <PremiumButton label="Pro plan" link />
//               </>
//             )}
//           </div>
//           <hr style={{ borderBottom: '1px solid rgb(230,230,230)' }} />
//           <div className="row">{availableModules}</div>
//           {/* <hr style={{ borderBottom: '1px solid rgb(230,230,230)' }} />
//                     <h1 style={{ textAlign: 'center' }}>R&D Lab<br/>(under construction)</h1>
//                     <p style={{ textAlign: 'center' }}>Vote for upgrade modules from our development roadmap or submit ideas as a Premium user to influence the future of Trevor AI.</p>
//                     <br/>
//                     <br/> */}
//           {/* <UpgradeModal module={modules[0]} isPremium={userIsPremium} isModuleActive={isModuleActive(modules[0])} onToggle={active => onToggleModule(modules[0].id, active)}/> */}
//         </PageScroll>
//       </PageBox>
//     );
//   }
// }

const DiscountCode = ({ onClose, onApply }) => {
  const [code, setCode] = useState('');
  const [status, setStatus] = useState(null);
  const handleSubmit = (e) => {
    e.preventDefault();
    setStatus('pending');
    onApply(code)
      .then(() => onClose())
      .catch((err) => setStatus(err === 'Precondition Failed' ? 'Invalid code' : 'Code already applied'));
  };
  const isPending = status === 'pending';
  const error = !isPending && status;
  return (
    <div style={{ maxWidth: '520px', margin: '0 auto' }}>
      <h1 className="premium-gradient" style={{ textAlign: 'center' }}>
        Apply Special Code
      </h1>
      <CloseIcon onClick={onClose} />
      <form onSubmit={handleSubmit}>
        <TextField
          autoFocus
          fullWidth
          autoComplete="off"
          id="custom-reminder-field"
          value={code}
          onChange={(e) => setCode(e.target.value)}
          placeholder="Special code"
          required
          disabled={isPending}
        />
        {!!error && <div style={{ color: 'red', padding: '10px 0' }}>{error}</div>}
        <div style={{ height: 10 }} />
        <PrimaryButton type="submit" disabled={isPending}>
          Apply
        </PrimaryButton>
      </form>
      <br />
      <CloseButton onClick={onClose} />
    </div>
  );
};

export const PricingTable = () => {
  return (
    <Box>
      <TableContainer
        component={Paper}
        sx={{
          maxWidth: 800,
          margin: '0 auto',
          boxShadow: 'none',
          '& thead tr': { height: '100px', verticalAlign: 'bottom' },
          '& thead tr th:nth-of-type(1)': { width: '40%' },
          '& thead tr th:nth-of-type(2), & thead tr th:nth-of-type(3), & thead tr th:nth-of-type(4)': { width: '20%' },
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Core Features</TableCell>
              <TableCell align="center">Free</TableCell>
              <TableCell align="center">Pro</TableCell>
              <TableCell align="center">Executive</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                Task Management
                <br />
                <span style={{ fontSize: '0.8em', color: 'gray' }}>via the Smart Task Hub</span>
              </TableCell>
              <TableCell align="center">
                <Check />
              </TableCell>
              <TableCell align="center">
                <Check />
              </TableCell>
              <TableCell align="center">
                <Check />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                Time Management
                <br />
                <span style={{ fontSize: '0.8em', color: 'gray' }}>via the Planner</span>
              </TableCell>
              <TableCell align="center">
                <Check />
              </TableCell>
              <TableCell align="center">
                <Check />
              </TableCell>
              <TableCell align="center">
                <Check />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                Unlimited Task Scheduling
                <br />
                <span style={{ fontSize: '0.8em', color: 'gray' }}>
                  with Realtime Sync between tasks and calendar events
                </span>
              </TableCell>
              <TableCell align="center">
                <Check />
              </TableCell>
              <TableCell align="center">
                <Check />
              </TableCell>
              <TableCell align="center">
                <Check />
              </TableCell>
            </TableRow>
          </TableBody>
          <TableHead>
            <TableRow>
              <TableCell>Integrations</TableCell>
              <TableCell align="center">Free</TableCell>
              <TableCell align="center">Pro</TableCell>
              <TableCell align="center">Executive</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Connect Google Calendar Accounts</TableCell>
              <TableCell align="center">
                Limited
                <br />
                <span style={{ fontSize: '0.8em', color: 'gray' }}>to 1 Account</span>
              </TableCell>
              <TableCell align="center">Multiple Account</TableCell>
              <TableCell align="center">Multiple Account</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                Connect Microsoft Calendar Accounts
                <br />
                <span style={{ fontSize: '0.8em', color: 'gray' }}>Office 365 and Outlook</span>
              </TableCell>
              <TableCell align="center">
                Limited
                <br />
                <span style={{ fontSize: '0.8em', color: 'gray' }}>to 1 Account</span>
              </TableCell>
              <TableCell align="center">Multiple Account</TableCell>
              <TableCell align="center">Multiple Account</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Connect iCloud Calendar</TableCell>
              <TableCell align="center">Not yet</TableCell>
              <TableCell align="center">Not yet</TableCell>
              <TableCell align="center">Not yet</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Connect Google Tasks</TableCell>
              <TableCell align="center">
                Limited
                <br />
                <span style={{ fontSize: '0.8em', color: 'gray' }}>to 1 Integration</span>
              </TableCell>
              <TableCell align="center">
                <Check />
              </TableCell>
              <TableCell align="center">
                <Check />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Connect Microsoft To-Do</TableCell>
              <TableCell align="center">
                Limited
                <br />
                <span style={{ fontSize: '0.8em', color: 'gray' }}>to 1 Integration</span>
              </TableCell>
              <TableCell align="center">
                <Check />
              </TableCell>
              <TableCell align="center">
                <Check />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Connect Todoist</TableCell>
              <TableCell align="center">
                Limited
                <br />
                <span style={{ fontSize: '0.8em', color: 'gray' }}>to 1 Integration</span>
              </TableCell>
              <TableCell align="center">
                <Check />
              </TableCell>
              <TableCell align="center">
                <Check />
              </TableCell>
            </TableRow>
          </TableBody>
          <TableHead>
            <TableRow>
              <TableCell>AI Scheduling Features</TableCell>
              <TableCell align="center">Free</TableCell>
              <TableCell align="center">Pro</TableCell>
              <TableCell align="center">Executive</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Auto-Assign Task Duration</TableCell>
              <TableCell align="center">
                <Check />
              </TableCell>
              <TableCell align="center">
                <Check />
              </TableCell>
              <TableCell align="center">
                <Check />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>AI Scheduling Suggestions</TableCell>
              <TableCell align="center">
                <Check />
              </TableCell>
              <TableCell align="center">
                <Check />
              </TableCell>
              <TableCell align="center">
                <Check />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                Personal AI Scheduling Model
                <br />
                <span style={{ fontSize: '0.8em', color: 'gray' }}>that adapts with each planning session</span>
              </TableCell>
              <TableCell align="center"></TableCell>
              <TableCell align="center">
                <Check />
              </TableCell>
              <TableCell align="center">
                <Check />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                Smart Scheduling Queue
                <br />
                <span style={{ fontSize: '0.8em', color: 'gray' }}>with One-click scheduling</span>
              </TableCell>
              <TableCell align="center"></TableCell>
              <TableCell align="center">
                <Check />
              </TableCell>
              <TableCell align="center">
                <Check />
              </TableCell>
            </TableRow>
          </TableBody>
          <TableHead>
            <TableRow>
              <TableCell>AI Assistant Features</TableCell>
              <TableCell align="center">Free</TableCell>
              <TableCell align="center">Pro</TableCell>
              <TableCell align="center">Executive</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Break Down Tasks into 5 Actionable Steps</TableCell>
              <TableCell align="center">Limited</TableCell>
              <TableCell align="center">
                <Check />
              </TableCell>
              <TableCell align="center">
                <Check />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                Start My Day Emails
                <br />
                <span style={{ fontSize: '0.8em', color: 'gray' }}>
                  Daily Agenda, Overdues and AI Coaching, in your Inbox
                </span>
              </TableCell>
              <TableCell align="center">Limited</TableCell>
              <TableCell align="center">
                <Check />
              </TableCell>
              <TableCell align="center">
                <Check />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Ask Trevor to Execute Scheduling Commands</TableCell>
              <TableCell align="center">Limited</TableCell>
              <TableCell align="center">Limited</TableCell>
              <TableCell align="center">
                <Check />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Extra Context for LLM features</TableCell>
              <TableCell align="center"></TableCell>
              <TableCell align="center">
                <Check />
              </TableCell>
              <TableCell align="center">
                <Check />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>LLM-Powered Auto-Scheduling</TableCell>
              <TableCell align="center"></TableCell>
              <TableCell align="center"></TableCell>
              <TableCell align="center">Soon</TableCell>
            </TableRow>
          </TableBody>
          <TableHead>
            <TableRow>
              <TableCell>Advanced Scheduling Features</TableCell>
              <TableCell align="center">Free</TableCell>
              <TableCell align="center">Pro</TableCell>
              <TableCell align="center">Executive</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Set Default Scheduling Hours</TableCell>
              <TableCell align="center">
                <Check />
              </TableCell>
              <TableCell align="center">
                <Check />
              </TableCell>
              <TableCell align="center">
                <Check />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                Flexible Recurring Tasks
                <br />
                <span style={{ fontSize: '0.8em', color: 'gray' }}>schedule each recurrence</span>
              </TableCell>
              <TableCell align="center">
                <Check />
              </TableCell>
              <TableCell align="center">
                <Check />
              </TableCell>
              <TableCell align="center">
                <Check />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                Scheduled Recurring Tasks
                <br />
                <span style={{ fontSize: '0.8em', color: 'gray' }}>recurrences already scheduled</span>
              </TableCell>
              <TableCell align="center"></TableCell>
              <TableCell align="center">
                <Check />
              </TableCell>
              <TableCell align="center">
                <Check />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                Override Scheduling Calendar
                <br />
                <span style={{ fontSize: '0.8em', color: 'gray' }}>for a specific list</span>
              </TableCell>
              <TableCell align="center"></TableCell>
              <TableCell align="center">
                <Check />
              </TableCell>
              <TableCell align="center">
                <Check />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                Set Scheduling Blocks and link them to Lists
                <br />
                <span style={{ fontSize: '0.8em', color: 'gray' }}>
                  to limit suggestions to specific times and days
                </span>
              </TableCell>
              <TableCell align="center"></TableCell>
              <TableCell align="center">
                <Check />
              </TableCell>
              <TableCell align="center">
                <Check />
              </TableCell>
            </TableRow>
          </TableBody>
          <TableHead>
            <TableRow>
              <TableCell>Additional Features</TableCell>
              <TableCell align="center">Free</TableCell>
              <TableCell align="center">Pro</TableCell>
              <TableCell align="center">Executive</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Share Lists with Peers</TableCell>
              <TableCell align="center">
                <Check />
              </TableCell>
              <TableCell align="center">
                <Check />
              </TableCell>
              <TableCell align="center">
                <Check />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                Multiple Sign-In Options
                <br />
                <span style={{ fontSize: '0.8em', color: 'gray' }}>Google, Microsoft, Facebook, Email</span>
              </TableCell>
              <TableCell align="center">
                <Check />
              </TableCell>
              <TableCell align="center">
                <Check />
              </TableCell>
              <TableCell align="center">
                <Check />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>My Progress Dashboard</TableCell>
              <TableCell align="center"></TableCell>
              <TableCell align="center">
                <Check />
              </TableCell>
              <TableCell align="center">
                <Check />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

const Footer = ({ onClose }) => {
  return (
    <>
      <CloseButton onClick={onClose} />
      <hr style={{ margin: '50px 0' }} />
      <p style={{ color: 'black', textAlign: 'center' }}>
        Have any questions? Get in touch with us at{' '}
        <a href="mailto:support@trevorlabs.com" style={{ color: theme.palette.primary.main }}>
          support@trevorlabs.com
        </a>
        . We're here to help.
      </p>
    </>
  );
};

export const PremiumModal = ({ open = false, onClose, initialPlan = null, requiredModule = null }) => {
  const {
    plans,
    plansInfo,
    user,
    premiumUntil,
    premiumPlan,
    premiumPrice,
    premiumStatusVerbose,
    premiumTraits,
    isPremium,
    isTrialNoCardOver,
    activeSubscription, // status?: 'Cancelled' | 'Active' | 'PastDue' | 'Trial';
    activeSubscriptionId,
    // isFreeTrial,
    cancelSubscription: onCancelSubscription,
    applyCode: onApplyCode,
    subscriptionPendingProcessing,
  } = usePremium();

  const [specialPrice, setSpecialPrice] = useState(null);

  const subStatus = activeSubscription?.status;

  const heading = (text) => (
    <h1 className="premium-gradient" style={{ textAlign: 'center', fontWeight: 900, marginBottom: '60px' }}>
      {text}
    </h1>
  );
  let premiumSubtext = (
    <p style={{ margin: '24px', textAlign: 'center', opacity: '0.66' }}>
      7 day free trial included. 30-day money back guarantee. Cancel anytime.
    </p>
  );

  let rightToTrial = !premiumTraits.isTrialConsumed && !premiumTraits.hadPremium;
  const premButtonLabel = rightToTrial ? plans.prem : plans.premNoTrial;
  const annpremButtonLabel = rightToTrial ? plans.annprem : plans.annpremNoTrial;
  const handlePremClick = () => {
    setSelectedPlan(rightToTrial ? 'prem' : 'premNoTrial');
    Analytics.event({ category: 'Premium', action: 'Initiated ' + premButtonLabel });
    Analytics.tagManagerEvent({
      event: 'conversion_premium_upgrade_intent_prem',
      userEmail: user?.email || null,
    });
  };
  const handleAnnPremClick = () => {
    setSelectedPlan(rightToTrial ? 'annprem' : 'annpremNoTrial');
    Analytics.event({ category: 'Premium', action: 'Initiated ' + annpremButtonLabel });
    Analytics.tagManagerEvent({
      event: 'conversion_premium_upgrade_intent_annprem',
      userEmail: user?.email || null,
    });
  };

  const [selectedPlan, setSelectedPlan] = useState(initialPlan);
  // const subscriptionPendingProcessing = window.sessionStorage.getItem('subscriptionPendingProcessing') === 'true';
  const [enterDiscountCode, setEnterDiscountCode] = useState(false);

  const trialDuration = '7'; // in days

  const datePretty = moment(premiumUntil).format('LL');

  let remainingSub = premiumStatusVerbose;
  // if (activeSubscriptionId) {
  //   remainingSub = isFreeTrial
  //     ? 'Trial is active and first payment will be on '
  //     : 'Plan is active and will auto-renew on ';
  //   remainingSub +=
  //     datePretty + ' for ' + planPrice(premiumPlan, activeSubscription?.paddle?.next_payment_amount ?? premiumPrice);
  // } else {
  //   if (premiumPlan === 'patreon') {
  //     remainingSub = '';
  //   } else {
  //     remainingSub = 'Plan is cancelled and will expire on ' + datePretty;
  //   }
  // }
  if (premiumPlan === 'patreon') {
    remainingSub = '';
  }

  let renderModulesGrid = (
    <Box
      style={{
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, 340px)',
        gridGap: '25px 15px',
        justifyContent: 'space-evenly',
        padding: '50px 15px',
      }}
    >
      {premiumModules.map((module) => {
        return renderDarkModule(module);
      })}
    </Box>
  );

  const planDetailsStyle = {
    display: 'flex',
    flexDirection: 'column',
    // margin: '0 auto',
    gap: '10px',
    maxWidth: '380px',
    width: '100%',
    border: '1px solid rgba(0,0,0,0.15)',
    padding: '10px',
    boxSizing: 'border-box',
    borderRadius: '25px',
    lineHeight: '1.5',
    color: 'black',
    '& h1': { margin: '0', fontWeight: 500 },
    '& p': {
      fontWeight: 500,
      fontSize: '0.85em',
      color: (theme: any) => theme.palette.faded.main,
    },
  };

  let renderHighlight = (icon, title, description) => {
    return (
      <Box
        sx={{
          display: 'inline-flex',
          gap: '10px',
          border: '1px solid rgba(0,0,0,0.1)',
          borderRadius: '20px',
          padding: '15px',
        }}
      >
        {icon}
        <Box>
          <h4 style={{ margin: 0 }}>{title}</h4>
          <p style={{ margin: '10px 0 0 0', fontSize: '0.8em', color: 'rgb(125,140,150)' }}>{description}</p>
        </Box>
      </Box>
    );
  };

  let activePlanContent = (
    <>
      {heading(planTitle(premiumPlan) + ' plan is Active')}
      <CloseIcon onClick={onClose} />
      <img
        className="animated tada"
        src="/images/premium_success.svg"
        alt="success"
        style={{
          display: 'block',
          minHeight: '150px',
          width: '150px',
          margin: '10px auto',
          opacity: '0.7',
        }}
      />
      <br />
      <Box
        sx={{
          textAlign: 'center',
          maxWidth: '700px',
          borderLeft: '4px solid',
          borderColor: theme.palette.primary.main,
          padding: '10px 20px',
          margin: '10px auto 30px auto',
          backgroundColor: theme.palette.background.paper,
        }}
      >
        <p style={{ fontStyle: 'italic', margin: 0 }}>
          "Thank you for subscribing! Your support enables us to make Trevor AI smarter, more accessible, and
          increasingly more capable of helping you to achieve your goals."
        </p>
        <p style={{ fontWeight: 'bold', margin: '10px 0 0 0' }}>— Team Trevor AI</p>
      </Box>
      {/* TODO: Add Plan Grid to upsell to Executive, once it's available */}
      <PrimaryButton onClick={onClose}>Close</PrimaryButton>
      <p style={{ textAlign: 'center' }}>{remainingSub}</p>
      {activeSubscriptionId && (
        <>
          <Button
            variant="text"
            onClick={() => {
              Analytics.event({ category: 'Premium', action: 'Clicked Cancel Plan Button' });
              if (
                confirm(
                  'Are you sure you want to cancel your subscription? If you are experiencing issues please contact us at support@trevorlabs.com. Your plan will remain active until ' +
                    datePretty
                )
              ) {
                onCancelSubscription();
              }
            }}
            fullWidth
            className="upgrade-module-pricing"
          >
            <span style={{ color: 'red' }}>Cancel Plan</span>
          </Button>
        </>
      )}
      <h2 style={{ textAlign: 'center', marginTop: '50px' }}>Have you tried all of the features available to you?</h2>
      <PricingTable />
      <Footer onClose={onClose} />
    </>
  );

  let proPlanContent = (
    <>
      {requiredModule && (
        <Alert
          sx={{
            margin: '0 auto 40px auto',
            maxWidth: '600px',
            background: 'white',
            border: '1px solid rgba(0,0,0,0.25)',
            color: 'black',
            borderRadius: '14px',
            boxShadow: 'none',
          }}
          severity="info"
        >
          <AlertTitle>{requiredModule.title} requires a Pro plan.</AlertTitle>
          {requiredModule.description}
        </Alert>
      )}
      <h1 id="header-text" style={{ textAlign: 'center', fontWeight: 900 }}>
        The Assistant for <span className="premium-gradient">Ambitious Planners</span>
      </h1>
      <CloseIcon onClick={onClose} />
      <p
        style={{
          textAlign: 'center',
          fontWeight: 400,
          marginBottom: '30px',
          fontSize: '1em',
          color: 'rgb(125,140,150)',
          lineHeight: '1.5',
        }}
      >
        The average professional spends just{' '}
        <a
          href="https://blog.rescuetime.com/work-life-balance-study-2019/"
          target="_blank"
          style={{ color: 'rgb(125,140,150)', textDecoration: 'underline' }}
        >
          2.8 hours on productive task work per day
        </a>
        .
        <br />
        What will you achieve with an assistant that adapts to you?
      </p>
      {/* <Grid container spacing={2}> */}
      <Box sx={{ display: 'flex', justifyContent: 'center', gap: '20px', flexWrap: 'wrap' }}>
        <Box sx={planDetailsStyle}>
          <Box sx={{ margin: '0 18px' }}>
            <p style={{ margin: '5px 0 0 0' }}>Personal Use</p>
            <h1 className="green-gradient">Free Plan</h1>
            <p className="green-gradient" style={{ marginTop: 0 }}>
              Schedule your day with intuitive planning features, in your personal calendar.
            </p>
          </Box>
          {renderHighlight(
            <Event />,
            'Unlimited Scheduling',
            'Schedule all of your tasks in your personal calendars, without limits.'
          )}
          {renderHighlight(
            <LinkIcon />,
            'Connect your Personal Calendar',
            'Connect 1 external calendar account and 1 task manager integration, or use Trevor’s internal ones.'
          )}
          {renderHighlight(
            <TouchApp />,
            'Intuitive Planning Features',
            'Drag & Drop, Scheduling Suggestions, Flexible Recurrence Rules, Focus Mode and more...'
          )}
        </Box>
        <Box sx={{ ...planDetailsStyle, border: '1px solid #377da5' }}>
          <Box sx={{ margin: '0 18px' }}>
            <p style={{ margin: '5px 0 0 0' }}>Professional Use</p>
            <h1 className="premium-gradient">Pro Plan</h1>
            <p className="premium-gradient" style={{ marginTop: 0 }}>
              Schedule your day with an AI that adapts to both your personal and professional life.
            </p>
          </Box>
          {renderHighlight(
            <SmartSchedulingIcon color="rgba(0, 0, 0, 0.87)" />,
            'Effortless Scheduling with AI',
            'AI Scheduling Suggestions predict the optimal time and duration for each task - just click to schedule.'
          )}
          {renderHighlight(
            <AddLink />,
            'Connect Multiple Calendars and Apps',
            'Manage all of life and work in one place, with Google, Microsoft, Todoist and more...'
          )}
          {renderHighlight(
            <TrendingUp />,
            'Advanced Planning Features',
            'Recurring Tasks, Rescheduling Suggestions, Scheduling Blocks and so much more...'
          )}
          <PrimaryButton onClick={handleAnnPremClick} style={{ margin: '15px 0 0 0' }}>
            {annpremButtonLabel}
          </PrimaryButton>
          <SecondaryButton style={{ margin: 0 }} onClick={handlePremClick}>
            {premButtonLabel}
          </SecondaryButton>
          <p style={{ margin: '0 15px 5px 15px', textAlign: 'center' }}>
            {rightToTrial && `You will be charged after your ${trialDuration} free trial. `}Risk free. Secure payment.
            Cancel anytime.
          </p>
        </Box>
        <Box sx={planDetailsStyle}>
          <Box sx={{ margin: '0 18px' }}>
            <p style={{ margin: '5px 0 0 0' }}>Coming Soon</p>
            <h1 className="black-gradient">Executive Plan</h1>
            <p className="black-gradient" style={{ marginTop: 0 }}>
              Schedule your day with an AI assistant that can execute your commands and learn more about you.
            </p>
          </Box>
          {renderHighlight(
            <Chat />,
            'Give Commands to your Trevor AI',
            'Issue Text and Voice commands and questions related to your scheduling needs.'
          )}
          {renderHighlight(
            <AssignmentInd />,
            'Context Awareness',
            'Give your Trevor AI all the necessary information to improve scheduling accuracy.'
          )}
          {renderHighlight(
            <ContentPaste />,
            'GPT-Powered Auto-Scheduling',
            'Combine AI Scheduling Suggestions with Context Awareness to elevate scheduling automation.'
          )}
          <PrimaryButton
            style={{
              margin: '15px 0 0 0',
              opacity: '0.5',
              background: 'linear-gradient(to top right, black 0%, #969696 100%)',
            }}
            disabled
          >
            Upgrade for $18/mo (coming soon)
          </PrimaryButton>
          <p style={{ margin: '0 15px 5px 15px', textAlign: 'center' }}>Risk free. Secure payment. Cancel anytime.</p>
        </Box>
      </Box>
      <Box sx={{ textAlign: 'center' }}>
        <p
          style={{
            display: 'inline-flex',
            alignItems: 'center',
            textAlign: 'left',
            maxWidth: '700px',
            margin: '50px auto 50px auto',
          }}
        >
          <StarIcon />
          <StarIcon />
          <StarIcon />
          <StarIcon />
          <StarIcon />
          <span style={{ paddingLeft: '15px' }}>
            <i>
              “This app is great for helping me with prioritizing my day and{' '}
              <strong>scheduling my most important tasks</strong> on my calendar, so that I make sure they get done.”
            </i>
            <br />— Anthony Gauna
          </span>
        </p>
      </Box>
      <PatreonButton />
      <SpecialCodeButton
        onClick={(e) => {
          e.preventDefault();
          setEnterDiscountCode(true);
        }}
      />
      <PricingTable />
      <br />
      <PrimaryButton
        fullWidth
        onClick={() => document.getElementById('header-text').scrollIntoView({ behavior: 'smooth' })}
      >
        Pick a Plan
      </PrimaryButton>
      <p style={{ textAlign: 'center', color: '#8998A7', margin: '0', fontSize: '0.85em', lineHeight: '1.5' }}>
        There's nothing to loose and a lot to gain.
        <br />
        Secure Payment. 30-Day Money Back. Cancel Anytime.
      </p>
      <Footer onClose={onClose} />
    </>
  );

  let earlyBirdContent = (
    <>
      {heading(planTitle(premiumPlan) + ' plan is Active')}
      <CloseIcon onClick={onClose} />
      <img
        className="animated tada"
        src="/images/premium_success.svg"
        alt="success"
        style={{ display: 'block', width: '150px', margin: '10px auto', opacity: '0.7' }}
      />
      <Box
        sx={{
          textAlign: 'center',
          maxWidth: '700px',
          borderLeft: '4px solid',
          borderColor: theme.palette.primary.main,
          padding: '10px 20px',
          margin: '10px auto 30px auto',
          backgroundColor: theme.palette.background.paper,
        }}
      >
        <p style={{ fontStyle: 'italic', margin: 0 }}>
          "Thank you for being one of our early supporters! All Pro features are enabled for your account, at no cost."
        </p>
        <p style={{ fontWeight: 'bold', margin: '10px 0 0 0' }}>— Co-founders George & Dmitry</p>
      </Box>
      <PrimaryButton onClick={onClose} style={{ maxWidth: '380px' }}>
        Close
      </PrimaryButton>
      <PatreonButton />
      <SpecialCodeButton
        onClick={(e) => {
          e.preventDefault();
          setEnterDiscountCode(true);
        }}
      />
      <h2 style={{ textAlign: 'center', marginTop: '50px' }}>Have you tried all of the features available to you?</h2>
      <PricingTable />
      <p style={{ fontSize: '0.9em', maxWidth: '800px', margin: '30px auto' }}>
        Trevor AI is developed by co-founders{' '}
        <a href="https://www.linkedin.com/in/george-petrov-a5b25147/" target="_blank">
          George Petrov
        </a>{' '}
        and{' '}
        <a href="https://www.linkedin.com/in/dmitryyudakov/" target="_blank">
          Dmitry Yudakov
        </a>
        , who started work on Trevor's web app on 10 Aug, 2016. Ever since, the co-founders have commited tens of
        thousands lines of code, with the purpose of helping you manage your day in a smarter way.
        <br />
        <br />
        Needless to say, two people couldn't have done it alone - they had Trevor and supporters like you.
      </p>
      <Footer onClose={onClose} />
    </>
  );

  let pastDueContent = (
    <>
      <h1 style={{ textAlign: 'center', fontWeight: 900, marginBottom: '60px', color: theme.palette.warning.main }}>
        Your {planTitle(premiumPlan)} plan payment is Past Due
      </h1>
      <CloseIcon onClick={onClose} />
      <Alert
        sx={{
          margin: '0 auto 60px auto',
          maxWidth: '600px',
          // background: 'rgba(0,0,0,0.1)',
          borderRadius: '14px',
        }}
        severity="warning"
      >
        <AlertTitle>
          Oops! It looks like there was a hiccup with your latest payment. No worries, though — there's enough time to
          sort it out. Just update your payment details within 7 days of the unsuccessful payment, and you'll be all set
          to continue enjoying all the Premium perks. We're here to help if you need us!
        </AlertTitle>
        <br />
        <PrimaryButton
          href={
            activeSubscription?.paddle?.update_url ||
            'mailto:support@trevorlabs.com?subject=Update%20Payment%20Link%20Not%20Working'
          }
          target="_blank"
        >
          Update Payment Method
        </PrimaryButton>
        <LinkButton href="mailto:support@trevorlabs.com?subject=Payment%20Issue">Contact Support</LinkButton>
        {activeSubscriptionId && (
          <>
            <Button
              variant="text"
              onClick={() => {
                Analytics.event({ category: 'Premium', action: 'Clicked Cancel Plan Button' });
                if (
                  confirm(
                    'Are you sure you want to cancel your subscription? If you are experiencing issues please contact us at support@trevorlabs.com. Your plan will remain active until ' +
                      datePretty
                  )
                ) {
                  onCancelSubscription();
                }
              }}
              fullWidth
              className="upgrade-module-pricing"
              sx={{ textTransform: 'none' }}
            >
              <span style={{ color: 'red' }}>Cancel Plan</span>
            </Button>
          </>
        )}
      </Alert>
      <h2 style={{ textAlign: 'center' }}>Update your payment details to continue enjoying all Premium features:</h2>
      <PricingTable />
      <Footer onClose={onClose} />
    </>
  );

  let discountCodeContent = <DiscountCode onApply={onApplyCode} onClose={() => setEnterDiscountCode(false)} />;

  const inProgressContent = (
    <>
      <p style={{ textAlign: 'center' }}>Just few more moments to prepare the subscription...</p>
      <CloseButton onClick={onClose} />
      <CloseIcon onClick={onClose} />
    </>
  );

  let content = proPlanContent; // Show Pro Plan Grid by default
  // activeSubscription.status?: 'Cancelled' | 'Active' | 'PastDue' | 'Trial';
  if (subscriptionPendingProcessing && !premiumTraits.hasActiveSubscription) {
    // Show progress bar until the paddle callback returns a subscription.
    content = inProgressContent;
  } else if (enterDiscountCode) {
    content = discountCodeContent;
  } else if (subStatus == 'PastDue') {
    // Failed Payment after trial - Paddle might convert to Cancelled automatically in 7 days - check settings. User will receive 3-4 reminder emails from Paddle during that time.
    content = pastDueContent;
  } else if (subStatus === 'Active' || subStatus === 'Trial' || premiumTraits.isPatreon) {
    // Paid user with active timer and plan
    content = activePlanContent;
  } else if (premiumTraits.isEarlyBird) {
    // early-bird
    content = earlyBirdContent;
  }

  console.log('dev PLAN: ', selectedPlan);

  return (
    <Dialog
      className="upgrade-modal"
      open={open}
      onClose={onClose}
      scroll="paper"
      fullWidth
      sx={{
        '.MuiDialog-paper': {
          maxWidth: 'initial',
          width: '100%',
          margin: '56px 5px 5px 5px',
          maxHeight: '100%',
          height: 'calc(100% - 61px)',
          background: 'linear-gradient(to top right, white 80%, rgb(235,235,235))',
          borderRadius: '28px',
        },
      }}
    >
      <DialogContent
        className="upgrade-modal-content"
        sx={{
          padding: '28px 15px 20px 15px !important', // overrides theme
          // padding 0 for mobile
          '@media (max-width: 1050px)': {
            padding: '28px 5px 20px 5px  !important',
          },
          '& button, a.MuiButton-root': {
            display: 'flex', // center
            maxWidth: '520px',
            height: '52px',
            margin: '10px auto',
          },
        }}
      >
        <Box sx={{ width: '1240px', maxWidth: '100%', margin: '0 auto' }}>
          {!selectedPlan && content}
          <PaddleCheckout
            open={!!selectedPlan}
            plan={selectedPlan}
            title={plans[selectedPlan]}
            // onSubcriptionCreated={() => window.sessionStorage.setItem('subscriptionPendingProcessing', 'true')}
            onSubcriptionCreated={() => saveToUserProfile(user.uid, { subscriptionPendingProcessing: true })}
            onSpecialPrice={(price) => {
              console.log('Apply special price', price);
              setSpecialPrice(price);
            }}
            onClose={() => {
              setSelectedPlan(null);
              setSpecialPrice(null);
            }}
            data={{
              user,
              // TODO premiumIntelligence
            }}
          />
        </Box>
      </DialogContent>
    </Dialog>
    // </div>
  );
};

const FeatureBox = ({ icon, title, description, active = false }) => {
  return (
    <Box
      sx={{
        padding: '15px',
        width: 370,
        backgroundColor: 'transparent',
        borderRadius: '15px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '12px',
        transition: 'box-shadow 0.3s ease, border 0.3s ease',
        boxShadow: active ? '0 4px 12px rgba(46, 106, 166, 0.2)' : 'none',
        border: active ? '1px solid rgba(46, 106, 166, 0.5)' : '1px solid rgb(230,230,230)',
        animation: active ? 'pulse 2s infinite' : 'none',
        '@keyframes pulse': {
          '0%': { boxShadow: '0 4px 8px rgba(46, 106, 166, 0.2)' },
          '50%': { boxShadow: '0 4px 12px rgba(46, 106, 166, 0.4)' },
          '100%': { boxShadow: '0 4px 8px rgba(46, 106, 166, 0.2)' },
        },
        '&:hover': {
          boxShadow: '0 4px 12px rgba(46, 106, 166, 0.4) !important', // Adds a subtle shadow
          border: '1px solid #2e6aa6', // Optional: Change border color on hover
        },
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
        {icon}
        <h3 style={{ margin: 0, fontSize: '1em' }}>{title}</h3>
      </Box>
      <p style={{ color: '#8998A7', margin: '0 0 0 36px', fontSize: '0.85em' }}>{description}</p>
    </Box>
  );
};

export const PremiumBenefits = () => {
  return (
    <>
      <FeatureBox
        icon={<SmartSchedulingIcon color="rgba(0, 0, 0, 0.87)" />}
        title="Schedule your Day with a Click"
        description="AI Scheduling Suggestions predict the optimal time and duration for each task - just click to schedule."
        active
      />
      <FeatureBox
        icon={<LinkIcon sx={{ fontSize: '22px' }} />}
        title="Connect your Calendars and Apps"
        description="Manage all of life and work in one place, with Google, Microsoft, Todoist and more..."
      />
      <FeatureBox
        icon={<TrendingUp sx={{ fontSize: '22px' }} />}
        title="Improve Every Day with Pro Features"
        description="Recurring Tasks, Rescheduling Suggestions, Chat with Trevor, Daily Coaching and more..."
      />
    </>
  );
};

export const TrialModal = ({ userObj }) => {
  const user = userObj || {};
  const { plansInfo, plans, isPremium, subscriptionPendingProcessing, isTrialNoCardOver } = usePremium();
  const [specialPrice, setSpecialPrice] = useState(null);
  // const { data: plansData, loading } = useFirebaseReadOnce('config-public/plans');
  // const plansInfo = plansData || defaultPlans;

  const [pricingOptionsOpen, setPricingOptionsOpen] = useState(false);
  const trialDuration = '7';
  const discount = '25%';

  const premButtonLabel = isTrialNoCardOver ? plans.premNoTrial : plans.prem;
  const annpremButtonLabel = isTrialNoCardOver ? plans.annpremNoTrial : plans.annprem;

  const handleClaimDiscountClick = () => {
    setPricingOptionsOpen(true);
  };

  const handleClosePricingOptions = () => {
    setPricingOptionsOpen(false);
  };

  const handleSelectedPlan = (plan) => {
    Analytics.event({ category: 'Premium', action: 'Initiated ' + plans[plan] + 'from Trial Modal' });
    Analytics.tagManagerEvent({ event: `conversion_trial_to_${plan}`, userEmail: user?.email || null });
    PremiumModalDock.showPlans(plan);
  };

  const handleActivateTrial = () => {
    savePremiumContext({ trial: true }).catch((err) => console.error('Error saving trial context', err));
  };

  useEffect(() => {
    // if it is, premium must've become activated here - save the premium context done and it will move on
    if (isPremium) {
      // could also show a success message here
      savePremiumContext({}).catch((err) => console.error('Error saving premium context', err));
    }
  }, [isPremium]);

  const inProgress = subscriptionPendingProcessing && !isPremium;
  // TODO use this to show in progress content

  return (
    <Dialog open={true} onClose={() => {}} sx={{ '.MuiPaper-root': { margin: '10px' } }}>
      <DialogContent
        sx={{
          maxWidth: 600,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '10px',
        }}
      >
        <h1 style={{ textAlign: 'center', fontWeight: 520, fontSize: '26px', margin: '20px 40px 10px 40px' }}>
          {trialDuration} Days of Planning with{' '}
          <span
            style={{
              background: 'linear-gradient(to right, #2e6aa6 0%, #72ccb4 100%)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
            }}
          >
            Trevor AI Pro
          </span>
        </h1>
        <p style={{ textAlign: 'center', marginTop: 0, color: '#8998A7' }}>
          Schedule your daily tasks with <u>an assistant that adapts to you</u>.
        </p>
        <PremiumBenefits />
        <Button
          fullWidth
          variant="contained"
          color="primary"
          onClick={handleClaimDiscountClick}
          sx={{
            maxWidth: 400,
            borderRadius: '15px',
            padding: '12px 15px',
            textTransform: 'initial',
            marginTop: '16px',
          }}
        >
          Claim {discount} Discount and Start {trialDuration} Day Free Trial
        </Button>
        <p style={{ textAlign: 'center', color: '#8998A7', margin: '0', fontSize: '0.85em' }}>
          Subscribe to a Pro plan with a {discount} discount and a {trialDuration} day free trial.
          <br />
          Risk free. Cancel anytime.
        </p>
      </DialogContent>
      <DialogActions
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Button
          fullWidth
          variant="outlined"
          onClick={handleActivateTrial}
          sx={{
            maxWidth: 400,
            color: 'black',
            borderColor: 'rgb(230,230,230)',
            borderRadius: '15px',
            textTransform: 'initial',
          }}
        >
          Skip
        </Button>
        <p style={{ textAlign: 'center', color: '#8998A7', margin: '10px', fontSize: '0.85em' }}>
          Start a {trialDuration} day Pro trial, then downgrade to a Free plan. Discount will not be applied.
        </p>
      </DialogActions>
      <Dialog open={pricingOptionsOpen} onClose={handleClosePricingOptions}>
        <DialogContent
          sx={{
            maxWidth: 600,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '10px',
          }}
        >
          <h1 style={{ textAlign: 'center', fontWeight: 520, fontSize: '26px', margin: '20px 40px 10px 40px' }}>
            Discount applied! Pick a plan.
          </h1>
          <img
            className="animated tada"
            src="/images/premium_success.svg"
            alt="success"
            style={{
              display: 'block',
              minHeight: '150px',
              width: '150px',
              margin: '25px auto',
              opacity: '0.7',
            }}
          />
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={() => {
              handleSelectedPlan('annprem');
            }}
            sx={{ width: 400, borderRadius: '15px', padding: '12px 15px', textTransform: 'initial' }}
          >
            {annpremButtonLabel}
          </Button>
          <p style={{ textAlign: 'center', color: '#8998A7', margin: '0 0 15px 0', fontSize: '0.85em' }}>
            You will be charged {planPrice('annprem', specialPrice ?? null, plansInfo)} after the {trialDuration} day
            free trial.
          </p>
          <Button
            fullWidth
            variant="outlined"
            // color="primary"
            onClick={() => {
              handleSelectedPlan('prem');
            }}
            sx={{ width: 400, borderRadius: '15px', padding: '12px 15px', textTransform: 'initial' }}
          >
            {premButtonLabel}
          </Button>
          <p style={{ textAlign: 'center', color: '#8998A7', margin: '0 0 15px 0', fontSize: '0.85em' }}>
            You will be charged {planPrice('prem', specialPrice ?? null, plansInfo)} after the {trialDuration} day free
            trial.
          </p>
          <Button
            fullWidth
            variant="outlined"
            onClick={() => {
              Analytics.event({ category: 'Premium', action: 'Opened Premium Modal from Trial Modal' });
              Analytics.tagManagerEvent({ event: 'conversion_trial_to_premium_modal', userEmail: user?.email || null });
              PremiumModalDock.showPlans();
            }}
            sx={{
              width: 400,
              color: 'black',
              borderColor: 'rgb(230,230,230)',
              padding: '12px 15px',
              borderRadius: '15px',
              textTransform: 'initial',
            }}
          >
            Explore Plans and Features
          </Button>
          <p style={{ textAlign: 'center', color: '#8998A7', margin: '0', fontSize: '0.85em' }}>
            30-day money back guarantee.
            <br />
            Risk free. Cancel anytime.
          </p>
        </DialogContent>
      </Dialog>
    </Dialog>
  );
};

let g_instPremiumModalDock = null;
const MODAL = '__MODAL__';
export class PremiumModalDock extends Component {
  state = {
    open: null,
    onClose: null,
    initialPlan: null,
  };
  componentDidMount() {
    g_instPremiumModalDock = this;
  }
  static showPlans(initialPlan = null) {
    g_instPremiumModalDock.setState({
      open: MODAL,
      initialPlan,
    });
  }
  static showUpgradeModule(moduleId, onClose = null) {
    Analytics.event({
      category: 'Premium',
      action: 'Show Upgrade Required: ' + moduleId,
    });
    g_instPremiumModalDock.setState({
      open: moduleId,
      onClose,
    });
  }
  render() {
    const { open, initialPlan } = this.state;
    const onClose = () => {
      const _onClose = this.state.onClose;
      this.setState({ open: null, onClose: null, initialPlan: null });
      _onClose && _onClose();
    };
    if (open === MODAL) return <PremiumModal open onClose={onClose} initialPlan={initialPlan} />;
    if (open) return <PremiumModal open requiredModule={premiumModules.find((m) => m.id === open)} onClose={onClose} />; // Shows premium modal with 'module required' alert
    // if (open) return <UpgradeModal open module={modules.find((m) => m.id === open)} onClose={onClose} />; // Legacy
    return <span />;
  }
}

export const PremiumButton = ({ fullWidth = false, style = undefined, label, link = false }) => {
  const { premiumStatusButton, premiumTraits, isExpired, isPremium } = usePremium();
  // const expired = moment().isAfter(user.premiumUntil, 'day');
  // const defaultLabel =
  //   user.premiumPlan && user.premiumPlan !== 'free'
  //     ? isExpired
  //       ? 'Renew Pro Plan'
  //       : 'Pro Plan Active'
  //     : 'Upgrade to Pro';

  const handleOpen = (e) => {
    e.preventDefault();
    Analytics.event({ category: 'Premium', action: 'Opened Premium Modal with ' + (label || premiumStatusButton) });
    PremiumModalDock.showPlans();
  };

  let openbutton = (
    <Button
      variant="contained"
      fullWidth={fullWidth}
      color="primary"
      onClick={handleOpen}
      disabled={premiumTraits.isSubscriptionCancelled && isPremium}
      // sx={{ padding: '0 10px 0 10px', color: 'white' }}
      style={style}
    >
      {/* <span style={{ color: 'white' }}> */}
      {label || premiumStatusButton || 'Open Pro Plan'}
      {/* </span> */}
    </Button>
  );
  if (link) {
    openbutton = (
      <a
        onClick={handleOpen}
        href=""
        style={{
          ...style,
          textDecoration: 'none',
          pointerEvents: premiumTraits.isSubscriptionCancelled && isPremium ? 'none' : 'auto',
          color: premiumTraits.isSubscriptionCancelled && isPremium ? 'gray' : 'inherit',
          cursor: premiumTraits.isSubscriptionCancelled && isPremium ? 'default' : 'pointer',
        }}
        aria-disabled={premiumTraits.isSubscriptionCancelled && isPremium}
      >
        {label || premiumStatusButton || 'Open Pro Plan'}
      </a>
    );
  }
  return <div style={{ display: 'inline-block', width: fullWidth ? '100%' : '' }}>{openbutton}</div>;
};

const UpgradeButtons = (module, isPremium, isActive, onToggle) => {
  if (isActive) {
    // console.log("Module Active")
    const deactivate = () => onToggle(false);
    if (isPremium) {
      // console.log("Module Premium User")
      return (
        <>
          <SecondaryButton disabled>Module is enabled (Pro)</SecondaryButton>
          {!module.isPremium && <RedButton onClick={deactivate}>Disable Module</RedButton>}
        </>
      );
      // if(module.price) {
      //     console.log("Module Priced")
      //     return (<><PrimaryButton>Module is enabled (Pro)</PrimaryButton>
      //             <RedButton onClick={deactivate}>Disable Module</RedButton></>)
      // } else { // !module.price
      //     console.log("Module Free")
      //     return (<><PrimaryButton disabled>Module is enabled (Pro)</PrimaryButton>
      //             <RedButton onClick={deactivate}>Disable Module</RedButton></>)
      // }
    } else {
      // !isPremium
      // console.log("Module Not-Premium User")
      return (
        <>
          <SecondaryButton disabled>Module is enabled</SecondaryButton>
          <RedButton onClick={deactivate}>Disable Module</RedButton>
        </>
      ); // isPremium modules get auto-disabled
      // // if(module.price) {
      //     console.log("Module Priced")
      //     return (<>
      //         <PrimaryButton disabled>Module is enabled for {modulePriceLabel(module)}</PrimaryButton>
      //         <RedButton onClick={deactivate}>Disable Module</RedButton>
      //     </>)
      // } else { // !module.price
      //     console.log("Module Free")
      //     return <>
      //         <PrimaryButton disabled>Module is enabled and free to use</PrimaryButton>
      //         <RedButton onClick={deactivate}>Disable Module</RedButton>
      //     </>
      // }
    }
  } else {
    // console.log("Module Inactive")
    const activate = () => onToggle(true);
    if (isPremium) {
      // console.log("Module Premium User")
      return <PrimaryButton onClick={activate}>Enable Module (Pro)</PrimaryButton>;
      // if(module.price) {
      //     console.log("Module Priced")
      //     return <SecondaryButton onClick={activate}>Enable Module (Pro)</SecondaryButton>
      // } else { // !module.price
      //     console.log("Module Free")
      //     return <SecondaryButton onClick={activate}>Enable Module (Pro)</SecondaryButton>
      // }
    } else {
      // !isPremium
      // console.log("Module Not-Premium User")
      return (
        <PremiumButton
          fullWidth
          label="Enable with a Pro Plan"
          // style={{color: 'white'}}
        />
      );
      // if(module.tier === 1) {
      //     console.log("Module has tier")
      //     return (<div>
      //         {/* <SecondaryButton>Upgrade for {modulePriceLabel(module)}</SecondaryButton> */}
      //         <PremiumButton fullWidth label="Upgrade with Pro"/></div>)
      // }
      // if(module.price) {
      //     console.log("Module Priced")
      //     return (<div>
      //         <SecondaryButton>Upgrade for {modulePriceLabel(module)}</SecondaryButton>
      //         <PremiumButton fullWidth label="Upgrade with Pro"/></div>)
      // } else { // !module.price
      //     console.log("Module Free")
      //     return <SecondaryButton onClick={activate}>Enable Module for free</SecondaryButton>
      // }
    }
  }
};

// const UpgradeModal = connect(
//   (state) => {
//     let user = state.account.user;
//     return {
//       user,
//     };
//   },
//   () => {
//     const onToggleModule = async (id, active) => {
//       try {
//         Analytics.event({
//           category: 'Premium',
//           action: id + ' module manually ' + (active ? 'enabled' : 'disabled'),
//         });
//         await saveModule(id, active);
//       } catch (e) {
//         console.log('Error saving module', e);
//         Analytics.event({
//           category: 'Premium',
//           action: 'Error saving ' + id + ' module',
//         });
//       }
//     };
//     return {
//       onToggleModule,
//     };
//   }
// )(({ open, isPremium, module, isModuleActive, onToggle, onClose, onToggleModule, user }) => {
//   // Modal used for detailed view of each Upgrade Module as well as a prompt to upgrade when functionality is locked.
//   if (isPremium === undefined) {
//     isPremium = user.isPremium;
//   }

//   if (isModuleActive === undefined) {
//     let activeModulesObj = user.modules || {};
//     isModuleActive = !!activeModulesObj[module.id];
//   }

//   let closeButton = (
//     <Button variant="text" fullWidth onClick={onClose} sx={{ color: 'rgb(135,220,230) !important' }}>
//       Close
//     </Button>
//   );
//   if (module.id === 'progress' && isModuleActive === false) {
//     closeButton = (
//       <Link to="/app/">
//         <Button fullWidth onClick={onClose}>
//           Go Back
//         </Button>
//       </Link>
//     );
//     onClose = null; // Prevents close onBackdropClick
//   }

//   if (onToggle === undefined) {
//     onToggle = (active) => onToggleModule(module.id, active);
//   }

//   return (
//     <Dialog className="upgrade-modal" open={open} onClose={onClose} scroll="paper">
//       <DialogTitle>Upgrade your Trevor</DialogTitle>
//       <DialogContent sx={{ maxWidth: '500px' }}>
//         {renderUpgradeModule(module, isPremium, isModuleActive, onToggle)}
//         {/* <p style={{ textAlign: 'center' }}>
//           <strong>7 day free trial included.</strong>
//         </p> */}
//         <Stack spacing={1} sx={{ display: 'flex', flexDirection: 'column', marginTop: '8px' }}>
//           {/* <Button
//             variant="text"
//             fullWidth
//             // className="upgrade-modal-link"
//             component={Link}
//             to="/app/upgrades"
//             onClick={() => onClose()}
//           >
//             Browse all Upgrade Modules
//           </Button> */}
//           {closeButton}
//         </Stack>
//       </DialogContent>
//     </Dialog>
//   );
// });

// export const AccountSettingsComponent = connect((state) => ({
//   user: state.account.user,
// }))(({ user }) => {
//   const { modules } = user; // only active modules stored in Redux
//   return (
//     <>
//       <h3>
//         Enabled Trevor Upgrades: {Object.keys(modules).length} / {totalModules}{' '}
//       </h3>
//       <p>Empower your Trevor with upgrades that improve your planning and boost your productivity</p>
//       <Button
//         variant="outlined"
//         fullWidth
//         // className="upgrade-modal-link"
//         component={Link}
//         to="/app/upgrades"
//       >
//         Browse Upgrade Modules
//       </Button>
//     </>
//   );
// });

// const moduleImageStyle = (module) => {
//   return {
//     backgroundImage: `url(/images/trevor_upgrade_` + module.imageSuffix + `)`,
//     backgroundSize: module.imageSize ? module.imageSize : '80px',
//     backgroundPosition: module.imagePosition ? module.imagePosition : 'center',
//     backgroundRepeat: 'no-repeat',
//     '&:hover': {
//       backgroundImage: `url(/images/trevor_upgrade_` + module.imageSuffix + `_hover.svg)`, // Not supported by react yet.
//     },
//   };
// };

const moduleImageDarkStyle = (module) => {
  return {
    minWidth: '60px',
    // height: '80px',
    alignItems: 'center',
    backgroundImage: `url(/images/trevor_upgrade_` + module.imageSuffix + `)`,
    backgroundSize: module.imageSize ? module.imageSize : '60px',
    backgroundPosition: module.imagePosition ? module.imagePosition : 'center',
    backgroundRepeat: 'no-repeat',
    '&:hover': {
      backgroundImage: `url(/images/trevor_upgrade_` + module.imageSuffix + `_hover.svg)`, // Not supported by react yet.
    },
    filter: 'invert(1)',
  };
};

const renderDarkModule = (module, isActive = false) => {
  return (
    <Box key={module.title} sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
      <h3 style={{ textAlign: 'center', fontWeight: 300, color: 'rgb(135,220,230)' }}>{module.benefit}</h3>
      <Box
        sx={{
          borderRadius: '16px',
          boxShadow: '-1px -1px 0 rgba(80,210,255,0.75), 1px 1px 0 rgba(130,230,200,0.75)',
          background:
            'linear-gradient(113deg, rgba(255, 255, 255, 0.17) -2.28%, rgba(255, 255, 255, 0.20) 51.75%, rgba(255, 255, 255, 0.00) 105.78%, rgba(255, 255, 255, 0.17) 105.78%)',
          padding: '15px 15px 15px 20px',
          display: 'flex',
          flexDirection: 'row',
          gap: '20px',
          flex: 1,
          transition: 'box-shadow 0.15s ease',
          '&:hover': { boxShadow: '0px 0px 15px rgba(135,220,230,0.75)' },
        }}
      >
        <Box
          style={moduleImageDarkStyle(module)}
          // alt={module.title} // doesn't exist on this component
        ></Box>
        <Box>
          <h4 style={{ fontSize: '17px', fontWeight: 400, margin: 0 }}>{module.title}</h4>
          <p style={{ color: '#B9D1D8', fontSize: '14px', fontWeight: 400, margin: '1em 0 0 0' }}>
            {module.description}
          </p>
        </Box>
      </Box>
    </Box>
  );
};

// const renderPassiveModule = (module, isActive = false) => {
//   return (
//     <div key={module.title}>
//       <div className="upgrade-modal-module upgrade-modal-passive">
//         <div className={'module-socket' + (isActive ? ' module-active' : ' module-demo')}>
//           <div className="module-chip-container">
//             <div className="module-chip">
//               <div className="module-icon" style={moduleImageStyle(module)} alt={module.title}></div>
//               <div className="module-title">{module.title}</div>
//             </div>
//             <div className="module-chip-back">
//               <div className="module-chip-description">{module.description}</div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// const renderUpgradeModule = (module, isPremium, isActive, onToggle) => {
//   return (
//     <div>
//       <div className="upgrade-modal-module">
//         <div className={'module-socket' + (isActive ? ' module-active' : ' module-demo')}>
//           <div className="module-chip">
//             <div className="module-icon" style={moduleImageStyle(module)} alt={module.title}></div>
//             <div className="module-title">{module.title}</div>
//           </div>
//         </div>
//       </div>
//       <p>{module.description}</p>
//       <br />
//       {UpgradeButtons(module, isPremium, isActive, onToggle)}
//     </div>
//   );
// };

// export default connect(
//   (state) => {
//     // console.log('premium')
//     let user = state.account.user;
//     return {
//       user,
//     };
//   },
//   () => {
//     const onToggleModule = async (id, active) => {
//       try {
//         Analytics.event({
//           category: 'Premium',
//           action: id + ' module manually ' + (active ? 'enabled' : 'disabled'),
//         });
//         await saveModule(id, active);
//       } catch (e) {
//         console.log('Error saving module', e);
//         Analytics.event({
//           category: 'Premium',
//           action: 'Error saving ' + id + ' module',
//         });
//       }
//     };
//     return {
//       onActivateFreeTrial: activateFreeTrial,
//       onToggleModule,
//     };
//   }
// )(Premium);
